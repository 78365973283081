import React from "react";

const Election = () => {
    return (
        <div>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
            <h1>Election</h1>
        </div>
    );
};

export default Election;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const defaultImages = [
    "https://images.staticcontent.io/rde/login/main-banner0.webp",
    "https://images.staticcontent.io/rde/login/main-banner2.webp",
];

const ImageSlider = ({ image = defaultImages }) => {
    const imagesArray = Array.isArray(image) ? image : defaultImages;
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        appendDots: (dots) => (
            <div className="absolute bottom-2 w-full flex justify-center">
                <ul className="m-0 p-0"> {dots} </ul>
            </div>
        ),
    };

    return (
        <div className="w-full h-auto">
            <Slider {...settings}>
                {imagesArray.map((img, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-center "
                    >
                        <img
                            src={img}
                            alt={`slide-${index}`}
                            className="w-full h-[8rem]  md:h-full  "
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;

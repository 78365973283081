import React from "react";
import { FaUserLarge } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router";
const LogoutSidebar = ({
    isOpenlogoutSidebar,
    isUserLogout,
    toggleLoggedIn,
}) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        toggleLoggedIn();
        navigate("/");
    };
    return (
        <div
            className={`fixed inset-0 z-[100] transform ${
                isOpenlogoutSidebar === false
                    ? "translate-x-full"
                    : "translate-x-0"
            } transition-transform duration-500 ease-in-out bg-black bg-opacity-70`}
        >
            <div className=" w-[60%] md:w-1/3 lg:w-[20%] h-full bg-[#045662] shadow-lg absolute right-0 top-0">
                <div className="p-4 flex justify-end">
                    <button
                        onClick={isUserLogout}
                        className="text-white text-xl"
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="p-4 flex flex-col gap-3 font-roboto-condensed">
                    <div className="flex items-center gap-3">
                        <FaUserLarge className="text-yellow-200" />

                        <div className="text-white text-[12px] md:text-sm">
                            Demo-(2000.00)
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div className="bg-[#17afc6] text-white border-2 border-white rounded-[5px] pl-3 py-1">
                            <div className="font-bold text-[12px] md:text-sm">
                                Exposure
                            </div>
                            <div className="text-black text-xs">0.00</div>
                        </div>
                        <div className="bg-[#17afc6] text-white border-2 border-white rounded-[5px] pl-3 py-1">
                            <div className="font-bold text-[12px] md:text-sm">
                                P&L
                            </div>
                            <div className="text-black text-xs">0.00</div>
                        </div>
                    </div>

                    <div className="flex items-center gap-3 text-white">
                        <img
                            className="w-5"
                            src="https://images.staticcontent.io/casino/banner/images/rules.svg"
                            alt="rule"
                        />
                        <div className="text-[12px] md:text-sm">Rules</div>
                    </div>
                    <div
                        className="flex items-center gap-3 text-white cursor-pointer"
                        onClick={handleLogout}
                    >
                        <img
                            className="w-5"
                            src="https://images.staticcontent.io/casino/banner/images/log-out.svg"
                            alt="logout"
                        />
                        <div className="text-[12px] md:text-sm">Logout</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutSidebar;

import React from "react";
import { Link } from "react-router-dom";

const HorseRacingScore = () => {
    return (
        <div className="w-full font-roboto-condensed bg-white">
            <div className="px-2 overflow-x-auto scrollbar-hide">
                {/* Title */}
                <div className="bg-[#18adc5] p-2">
                    <div className="text-white text-sm font-bold font-roboto-condensed">
                        Horse Racing Score
                    </div>
                </div>
                <div className="flex gap-2 overflow-x-auto scrollbar-hide">
                    {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                        <div
                            key={index}
                            className="flex-shrink-0 flex items-center space-x-2 p-2 bg-white rounded shadow "
                        >
                            <img
                                src="https://images.staticcontent.io/rde/login/horse-login.svg"
                                alt="Horse"
                                className="w-10 h-12 md:w-[35px] md:h-auto"
                            />
                            <Link to={`horse/horse-ntts/Horse/koria`}>
                                <div className="flex flex-col text-xs md:text-sm font-medium md:font-bold custom-button">
                                    <div>Kenilworth (RAS) 2nd June</div>
                                    <div>2nd June 17:06</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HorseRacingScore;

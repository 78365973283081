import React, { useEffect, useState } from "react";
import { GrAnnounce } from "react-icons/gr";

const Announcement = ({ isUserLoggedIn }) => {
    const [currTime, setCurrTime] = useState(
        new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrTime(
                new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const currDate = new Date();
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = currDate
        .toLocaleDateString("en-GB", options)
        .replace(/\//g, " ");

    return (
        <>
            {isUserLoggedIn ? (
                <div
                    className="flex justify-center bg-[#045662] w-full"
                    style={{
                        backgroundImage: "linear-gradient(#022c43, #18b0c8)",
                    }}
                >
                    {/* <div className="bg-red-600  text-white text-[13px] w-[145px] text-center rounded-[6px] py-[0.6px]">
                        Demo Account
                    </div> */}
                </div>
            ) : (
                <div className="flex justify-between items-center bg-[#045662] px-2 w-full h-[1.5rem]">
                    <div className="flex-none">
                        <GrAnnounce className="text-white w-6 h-4" />
                    </div>

                    <div className="flex flex-1 items-center overflow-hidden">
                        {/* moving text */}
                        <div className="marquee flex-1 overflow-hidden whitespace-nowrap text-white">
                            <div className="marquee__inner inline-block animate-marquee text-center pt-1">
                                <span className="inline-block text-xs">
                                    Enjoy Unmatched Betting Excitement and
                                    Access 500+ Casino and Online Games!
                                </span>
                                <span className="inline-block px-4 text-xs">
                                    Enjoy Unmatched Betting Excitement and
                                    Access 500+ Casino and Online Games!
                                </span>
                            </div>
                        </div>

                        {/* time & date */}
                        <div className="flex-none flex items-center text-white gap-[1px] font-bold font-sans ml-2 text-[10px] w-[5.6rem] whitespace-nowrap">
                            <div>{formattedDate}</div>
                            <div>{currTime}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Announcement;

import React from "react";
import Announcement from "./announcement/Announcement";
import BrandHeader from "./brandHeader/BrandHeader";
import Header from "./header/Header";

const Navbar = ({
    toggleDrawer,
    toggleLogin,
    isLoginOpen,
    isUserLoggedIn,
    isUserLogout,
}) => {
    return (
        <div className="w-full md:fixed md:z-30">
            <Announcement isUserLoggedIn={isUserLoggedIn} />
            <BrandHeader
                isLoginOpen={isLoginOpen}
                toggleDrawer={toggleDrawer}
                toggleLogin={toggleLogin}
                isUserLoggedIn={isUserLoggedIn}
                isUserLogout={isUserLogout}
            />
            <Header />
        </div>
    );
};

export default Navbar;

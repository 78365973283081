import React, { useEffect, useState } from "react";
import { MdPushPin } from "react-icons/md";
import { GiClockwork } from "react-icons/gi";
import BetControl from "../../components/betcontrol/BetControl";

const WcWinner = () => {
    const [data, setData] = useState(null);
    const [selectedScore, setSelectedScore] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const mockData = {
                winner: {
                    teams: [
                        { name: "India", scores: [9, "", 12, 15, 78, 85] },
                        { name: "Australia", scores: [9, "", 12, 15, "", 85] },
                        { name: "England", scores: [9, "", 12, 15, 78, 85] },
                    ],
                    min: 2.0,
                    max: 0.0,
                    team: "",
                },
                bookmarker: {
                    teams: [
                        { name: "India", scores: [9, 11, 15, 21, "", 85] },
                        { name: "Australia", scores: [9, 11, 15, 21, "", 85] },
                        { name: "England", scores: ["", 11, 15, 21, "", 85] },
                    ],
                    min: 1.8,
                    max: 3.5,
                },
                date: "21/06/2024 20:00",
                market: "Market",
                openBet: 0,
            };

            // Simulating async fetch
            setData(mockData);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        };

        fetchData();
    }, []);
    const handleScoreClick = (score, section) => {
        setSelectedScore(score);
        setSelectedSection(section); // Set the selected section to show BetControl
    };

    const handleCloseBtn = () => {
        setSelectedSection(null); // Close the BetControl component
    };

    return (
        <div className="flex overflow-hidden w-full">
            {/* In-play section */}
            <div className="flex-1 flex flex-col overflow-y-auto mx-1">
                <div className="bg-[#18adc5]">
                    <div className="flex flex-col items-start w-full">
                        <div className="flex items-center gap-1 w-full text-white text-sm font-bold py-1 px-2">
                            <MdPushPin className="text-white border w-[1.2rem] h-[1.2rem] rounded-sm border-black" />
                            <div>ICC World Twenty20 - Winner</div>
                        </div>

                        <div className="flex items-center justify-between w-full bg-black text-xs font-semibold py-1 text-white px-2">
                            <div className="flex items-center gap-1">
                                <GiClockwork />
                                <div>{data ? data.date : ""}</div>
                            </div>
                            <div>
                                Min: {data ? data.winner.min : ""} | Max:{" "}
                                {data ? data.winner.max : ""}
                            </div>
                        </div>
                    </div>

                    <div className="">
                        {/* Winner */}
                        <div>
                            <div className="flex items-center justify-between w-full text-sm py-1 px-2 text-white">
                                <div className="font-bold text-white">
                                    Winner - 2024
                                </div>
                            </div>

                            {/* Table */}
                            <div className="bg-white text-black w-full">
                                {/* Header */}
                                <div className=" flex items-center justify-between">
                                    <div className="text-xs  w-1/2"></div>
                                    <div className="flex items-center justify-center  w-1/2">
                                        <div>&nbsp;</div>
                                        <div className="flex items-center justify-center gap-1 md:gap-[5px] my-1">
                                            <div className="bg-[#72bbef] text-center w-[4rem]  md:w-[5rem] rounded-[5px] font-bold">
                                                Back
                                            </div>
                                            <div className="bg-[#faa9ba] text-center w-[4rem] md:w-[5rem] rounded-[5px] font-bold">
                                                Lay
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                {/* Winner board */}
                                <div className="px-1">
                                    {data &&
                                        data.winner.teams.map((ele, index) => (
                                            <React.Fragment key={index}>
                                                <div className="grid grid-cols-2 w-full">
                                                    <div className="text-[14px] font-bold">
                                                        {ele.name}
                                                    </div>

                                                    <div className="flex justify-center md:justify-normal gap-1">
                                                        {ele.scores.map(
                                                            (
                                                                score,
                                                                scoreIndex
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        scoreIndex
                                                                    }
                                                                    className={`flex md:items-center text-black w-[4rem] md:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer ${
                                                                        scoreIndex ===
                                                                            2 ||
                                                                        scoreIndex ===
                                                                            3
                                                                            ? "block"
                                                                            : "hidden md:block"
                                                                    }`}
                                                                    style={{
                                                                        backgroundColor:
                                                                            scoreIndex %
                                                                                2 ===
                                                                            0
                                                                                ? "#72bbef"
                                                                                : "#faa9ba",
                                                                    }}
                                                                    onClick={() =>
                                                                        handleScoreClick(
                                                                            score,
                                                                            "winner"
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="flex items-center flex-col text-[10px] font-bold m-auto">
                                                                        {score}
                                                                        {score ? (
                                                                            <div>
                                                                                200
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <hr />
                                            </React.Fragment>
                                        ))}
                                    {selectedSection === "winner" && (
                                        <BetControl
                                            showBetControl={true}
                                            handleCloseBtn={handleCloseBtn}
                                            oddValue={selectedScore}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Bookmaker */}
                        <div>
                            <div className="flex items-center justify-between w-full text-sm py-1 px-2 text-white">
                                <div className="font-bold text-white">
                                    Bookmaker
                                </div>
                            </div>

                            <div className="bg-white text-black w-full">
                                {/* Header */}
                                <div className=" flex items-center justify-between">
                                    <div className="text-xs  w-1/2"></div>
                                    <div className="flex items-center justify-center  w-1/2">
                                        <div>&nbsp;</div>
                                        <div className="flex items-center justify-center gap-1 md:gap-[5px] my-1">
                                            <div className="bg-[#72bbef] text-center w-[4rem]  md:w-[5rem] rounded-[5px] font-bold">
                                                Back
                                            </div>
                                            <div className="bg-[#faa9ba] text-center w-[4rem] md:w-[5rem] rounded-[5px] font-bold">
                                                Lay
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                {/* Bookmaker board */}
                                <div className="px-1">
                                    {data &&
                                        data.bookmarker.teams.map(
                                            (ele, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="grid grid-cols-2 w-full">
                                                        <div className="text-[14px] font-bold">
                                                            {ele.name}
                                                        </div>

                                                        <div className="flex justify-center md:justify-normal gap-1">
                                                            {ele.scores.map(
                                                                (
                                                                    score,
                                                                    scoreIndex
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            scoreIndex
                                                                        }
                                                                        className={`flex md:items-center text-black w-[4rem] md:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer ${
                                                                            scoreIndex ===
                                                                                2 ||
                                                                            scoreIndex ===
                                                                                3
                                                                                ? "block"
                                                                                : "hidden md:block"
                                                                        }`}
                                                                        style={{
                                                                            backgroundColor:
                                                                                scoreIndex %
                                                                                    2 ===
                                                                                0
                                                                                    ? "#72bbef"
                                                                                    : "#faa9ba",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleScoreClick(
                                                                                score,
                                                                                "bookmaker"
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="flex items-center flex-col text-[10px] font-bold m-auto">
                                                                            {
                                                                                score
                                                                            }
                                                                            {score ? (
                                                                                <div>
                                                                                    200
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </React.Fragment>
                                            )
                                        )}
                                    {selectedSection === "bookmaker" && (
                                        <BetControl
                                            showBetControl={true}
                                            handleCloseBtn={handleCloseBtn}
                                            oddValue={selectedScore}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Match bet section */}
            <div className="hidden md:flex bg-white flex-col gap-[1px] font-[roboto-condensed] w-1/4 overflow-auto">
                <div
                    style={{
                        backgroundColor: "#5ecbdd",
                        backgroundImage: "linear-gradient(#5ecbdd, #146578)",
                        color: "#fff",
                        paddingLeft: "0.5rem",
                    }}
                >
                    Matched Bet
                </div>
                <div className="grid grid-cols-4 gap-[1px] w-full text-white text-sm font-bold">
                    <div className="bg-[#028dad] col-span-2 pl-2">
                        Matched Bet
                    </div>
                    <div className="bg-[#028dad] pl-2">Odds</div>
                    <div className="bg-[#028dad] pl-2">Stake</div>
                </div>
            </div>
        </div>
    );
};

export default WcWinner;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
    "https://images.staticcontent.io/casino/without-login/topgame/topgame1.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame2.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame4.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame5.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame6.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame8.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame9.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame3.webp",
    "https://images.staticcontent.io/casino/without-login/topgame/topgame7.webp",
    // ... add all 15 image paths
];

const AllGames = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 7.1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="md:px-2">
            <div>
                <div className="font-bold md:text-[1rem] text-left text-sm  font-roboto-condensed py-1 md:py-2 pb-1">
                    Top Games
                </div>
            </div>
            <div className="w-full ">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="px-[1px] md:px-1">
                            <img
                                src={image}
                                alt={`Slide ${index}`}
                                className="mx-4 rounded-lg cursor-pointer"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default AllGames;
// export default AllGames;

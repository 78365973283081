import React from "react";

const GameGallery = () => {
    // Array of image objects
    const images = [
        {
            src: "https://images.staticcontent.io/rde/login/bonus-1.webp",
            alt: "Game 1",
        },
        {
            src: "https://images.staticcontent.io/rde/login/bonus-4.webp",
            alt: "Game 2",
        },
        {
            src: "https://images.staticcontent.io/rde/login/bonus-6.webp",
            alt: "Game 3",
        },
        {
            src: "https://images.staticcontent.io/rde/login/RDGIF-1.gif",
            alt: "Game 4",
        },
        {
            src: "https://images.staticcontent.io/rde/login/RDGIF-2.gif",
            alt: "Game 5",
        },
    ];

    return (
        <div className="grid gap-[2px] md:gap-2 w-full  md:px-2">
            <div className="grid grid-cols-3 gap-[2px] md:gap-1">
                {images.slice(0, 3).map((image, index) => (
                    <div key={index}>
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-auto"
                        />
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                {images.slice(3).map((image, index) => (
                    <div key={index}>
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-auto"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GameGallery;

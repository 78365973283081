import React from "react";
import { NavLink } from "react-router-dom";
import poker from "../../../assets/sidebar/indianPoker.png";
import inplay from "../../../assets/sidebar/inplay.png";
import wcwinner from "../../../assets/sidebar/wcWinner.png";
import cricket from "../../../assets/sidebar/cricket.png";
import soccer from "../../../assets/sidebar/soccer.png";
import tennis from "../../../assets/sidebar/tennis.png";
import horse from "../../../assets/sidebar/horse.png";
import greyhound from "../../../assets/sidebar/grahound.png";
import poker2 from "../../../assets/sidebar/indianPoker2.png";
import aviator from "../../../assets/sidebar/aviator.png";
import casino from "../../../assets/sidebar/casino-icon.svg";

const tabs = [
    {
        name: "Home",
        path: "/",
        image: null,
        hiddenOnSmallScreen: true,
    },
    {
        name: "InPlay",
        path: "/inplay",
        hasLiveIndicator: true,
        isBold: false,
        image: inplay,
    },
    {
        name: "WC Winner 2024",
        path: "/wcwinner",
        hasLiveIndicator: true,
        isBold: false,
        image: wcwinner,
    },
    {
        name: "IVLT 10",
        path: "/ivl",
        hasLiveIndicator: true,
        image: wcwinner,
        liveCount: true,
    },
    {
        name: "Cricket",
        path: "/cricket",
        hasLiveIndicator: true,
        liveCount: true,
        image: cricket,
    },
    {
        name: "Soccer",
        path: "/soccer",
        hasLiveIndicator: true,
        liveCount: true,
        image: soccer,
    },
    {
        name: "Tennis",
        path: "/tennis",
        hasLiveIndicator: true,
        liveCount: false,
        image: tennis,
    },
    {
        name: "Horse",
        path: "/horse",
        hasLiveIndicator: true,
        liveCount: false,
        image: horse,
    },
    {
        name: "Greyhound",
        path: "/greyhound",
        hasLiveIndicator: true,
        liveCount: false,
        image: greyhound,
    },
    {
        name: "Indian Poker",
        path: "/indianpoker",
        image: poker,
    },
    {
        name: "Indian Poker ||",
        path: "/indianpoker2",
        image: poker2,
    },
    {
        name: "Aviator",
        path: "/aviator",
        image: aviator,
    },
    {
        name: "AESEXY",
        path: "/aesexy",
        image: casino,
    },
    {
        name: "Evolution",
        path: "/evolution",
        image: casino,
    },
    {
        name: "Live Casino",
        path: "/livecasino",
        image: casino,
    },
    {
        name: "Vivo",
        path: "/vivo",
        image: casino,
    },
    {
        name: "Betgames",
        path: "/betgames",
        image: casino,
    },
    {
        name: "Casino |||",
        path: "/casino",
        image: casino,
    },
];

const colors = ["red", "blue", "purple", "green", "yellow"];

const Header = () => {
    return (
        <div className="bg-[#045662] w-full h-[3.5rem] overflow-hidden css-custom ">
            <div className="flex lg:pt-4 pt-2  items-center gap-2 lg:gap-1 h-auto lg:h-[3.5rem] text-white font-bold text-xs md:text-sm overflow-x-auto scrollbar-hide ">
                {tabs.map((tab, index) => (
                    <NavLink
                        key={index}
                        to={tab.path}
                        className={({ isActive }) =>
                            `flex items-center px-2 lg:py-1 rounded- ${
                                isActive
                                    ? "text-black"
                                    : "hover:bg-[#007bff] text-white"
                            } ${
                                tab.hiddenOnSmallScreen ? "hidden lg:flex" : ""
                            }`
                        }
                        style={({ isActive }) =>
                            isActive
                                ? {
                                      backgroundColor: "#5ecbdd",
                                      backgroundImage:
                                          "linear-gradient(#5ecbdd, #146578)",
                                      color: "white",
                                      minHeight: "2.5rem",
                                      maxHeight: "2.5rem",
                                  }
                                : {}
                        }
                    >
                        <div
                            className="flex items-center flex-col gap-1 py-1 lg:py-0 whitespace-nowrap text-xs font-bold relative"
                            style={{
                                color: tab.isBold
                                    ? colors[index % colors.length]
                                    : "inherit",
                            }}
                        >
                            {tab.image && (
                                <img
                                    src={tab.image}
                                    alt={tab.name}
                                    className="w-6 h-6 rotate-90 lg:hidden"
                                />
                            )}

                            <span className="text-[10px] lg:text-sm">
                                {tab.name}
                            </span>

                            {tab.hasLiveIndicator && (
                                <span className=" text-[8px] lg:text-[12px] absolute top-13 left-4 lg:bottom-5 ">
                                    {tab.liveCount && (
                                        <div className="flex items-center font-normal ">
                                            <div className="bg-white text-red-600 rounded-l-sm px-[2px] ">
                                                Live&nbsp;
                                            </div>
                                            <div className="bg-red-600 text-white px-[4px] rounded-r-sm ">
                                                0
                                            </div>
                                        </div>
                                    )}
                                </span>
                            )}
                        </div>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default Header;

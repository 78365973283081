import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";

const FooterNav = ({ toggleLogin, isUserLogout, isUserLoggedIn }) => {
    return (
        <div className="bg-[#045662] px-2 w-full flex items-center justify-between h-[2.8rem] fixed bottom-0 rounded-tr-xl rounded-tl-xl">
            {isUserLoggedIn ? (
                <NavLink
                    to={"/home"}
                    className={({ isActive }) =>
                        `flex items-center gap-[5px] rounded-full justify-center px-4 ${
                            isActive
                                ? "bg-[#3498db] text-white"
                                : "bg-[#045662] text-white"
                        }`
                    }
                >
                    {({ isActive }) => (
                        <>
                            <FaHome className="h-[2rem]" />
                            {isActive && (
                                <div className="text-xs font-bold">Home</div>
                            )}
                        </>
                    )}
                </NavLink>
            ) : (
                <NavLink
                    to={"/"}
                    className={({ isActive }) =>
                        `flex items-center gap-[5px] rounded-full justify-center px-4 ${
                            isActive
                                ? "bg-[#3498db] text-white"
                                : "bg-[#045662] text-white"
                        }`
                    }
                >
                    {({ isActive }) => (
                        <>
                            <FaHome className="h-[2rem]" />
                            {isActive && (
                                <div className="text-xs font-bold">Home</div>
                            )}
                        </>
                    )}
                </NavLink>
            )}

            <NavLink
                to="/inplay"
                className={({ isActive }) =>
                    `flex items-center rounded-full justify-center px-3 py-1 ${
                        isActive
                            ? "bg-[#3498db] text-white"
                            : "bg-[#045662] text-white"
                    }`
                }
            >
                {({ isActive }) => (
                    <>
                        <FaRegCirclePlay className="text-white w-8 h-6" />
                        {isActive && (
                            <div className="text-white text-xs font-semibold">
                                Inplay
                            </div>
                        )}
                    </>
                )}
            </NavLink>
            <NavLink
                onClick={toggleLogin}
                to="/indianpoker"
                className={({ isActive }) =>
                    `flex items-center rounded-full justify-center px-2 py-1 ${
                        isActive
                            ? "bg-[#3498db] text-white"
                            : "bg-[#045662] text-white"
                    }`
                }
            >
                {({ isActive }) => (
                    <>
                        <div className="flex justify-center items-center">
                            <div className="relative w-8 h-6 perspective">
                                <div className="absolute w-full h-full transition-transform duration-[4s] transform-style-2d flip-animation">
                                    <img
                                        className="w-[1.5rem]"
                                        src="https://images.staticcontent.io/rde/uimg/mini_games.gif"
                                        alt="pokre"
                                    />
                                </div>
                            </div>
                            {isActive && (
                                <div className="text-white text-xs font-semibold">
                                    MiniGames
                                </div>
                            )}
                        </div>
                    </>
                )}
            </NavLink>
            <NavLink
                onClick={toggleLogin}
                to="/indianpoker2"
                className={({ isActive }) =>
                    `flex items-center rounded-full justify-center px-3 py-1 ${
                        isActive
                            ? "bg-[#3498db] text-white"
                            : "bg-[#045662] text-white"
                    }`
                }
            >
                {({ isActive }) => (
                    <>
                        <img
                            className="w-8 h-5"
                            src="https://images.staticcontent.io/rde/uimg/menu.svg"
                            alt="hamburger"
                            onClick={isUserLogout}
                        />
                        {isActive && (
                            <div className="text-white text-xs font-semibold">
                                Menu
                            </div>
                        )}
                    </>
                )}
            </NavLink>
        </div>
    );
};

export default FooterNav;

import React, { useState } from "react";

const AeSexy = () => {
    const [gameList] = useState([
        "https://images.staticcontent.io/casino/without-login/ae/400001.MX-LIVE-001.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400003.MX-LIVE-003.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400000.SEXYBCRT-LOBBY.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400005.MX-LIVE-007.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400010.MX-LIVE-013.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400335.AE-LOTTO-004.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400006.MX-LIVE-009.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400007.MX-LIVE-010.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400008.MX-LIVE-011.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400002.MX-LIVE-002.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400009.MX-LIVE-012.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400004.MX-LIVE-006.jpg",
        "https://images.staticcontent.io/casino/without-login/ae/400011.MX-LIVE-014.jpg",
    ]);

    return (
        <div className="flex flex-col w-full mb-[2rem] md:mb-0">
            <div className="bg-[#18adc5] p-2">
                <div className="text-white text-sm font-bold">Casino</div>
            </div>
            <div className="grid gap-2 p-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6  rounded-xl">
                {gameList.map((src, index) => (
                    <div
                        key={index}
                        className="relative flex justify-center  border-4 border-[rgba(0,0,0,0.6)] rounded-[5px] group"
                    >
                        <img
                            src={src}
                            alt={`game ${index}`}
                            className="w-full h-auto"
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <span className="text-white font-bold bg-[#18adc5] rounded-full px-4 py-1 text-sm hover:bg-[#343a40]">
                                Play
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AeSexy;

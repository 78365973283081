import React from "react";
import Soccer from "./../../../../pages/soccer/Soccer";
import { Link } from "react-router-dom";

const Tennis = () => {
    // Array of match objects
    const matches = [
        {
            date: "25 May 09:30",
            competition: "ICC World Twen...",
            status: "Live Now",
            scores: [250, 200, 180, 220, 260, 240],
        },
        {
            date: "26 May 10:00",
            competition: "IPL 2023",
            status: "Upcoming",
            scores: [210, 190, 230, 200, 240, 220],
        },
        {
            date: "27 May 11:30",
            competition: "Big Bash Leag..",
            status: "Live Now",
            scores: [200, 220, 210, 230, 250, 240],
        },
    ];

    return (
        <div className="w-full font-roboto-condensed flex flex-col">
            <div className="flex-grow">
                {/* Title */}
                <div className="bg-[#18adc5] p-1">
                    <div className="text-white text-sm font-bold">Tennis</div>
                </div>

                {/* Scores Header */}
                <div className="hidden lg:flex justify-end gap-[10rem] px-4 bg-gray-100">
                    <div className="text-lg font-semibold">1</div>
                    <div className="text-lg font-semibold">x</div>
                    <div className="text-lg font-semibold">2</div>
                </div>
                <hr />

                {/* Match Info */}
                {matches.map((match, index) => (
                    <React.Fragment key={index}>
                        <div className="flex items-center gap-2 flex-col lg:flex-row justify-between w-full px-1 pb-1  text-xs md:text-sm">
                            <div className="flex items-center gap-[0px] flex-col md:flex-row w-full ">
                                <div className="flex items-center justify-between order-2 md:order-1 w-full md:w-auto">
                                    <div className="text-[#545454] md:text-[#022c44] md:font-bold  text-[13px] md:text-[14px] whitespace-nowrap flex items-center">
                                        <div>
                                            {match.date}
                                            {match.time} |
                                        </div>
                                        <div className="flex md:hidden">
                                            {" "}
                                            Womens One Day Inte...
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:hidden flex border-2 flex-shrink-0">
                                            <img
                                                className="w-4 h-4"
                                                src="https://images.staticcontent.io/rde/uimg/a-pin-mobile.svg"
                                                alt="select multiple"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between w-full order-1 lg:order-2">
                                    <div className="flex items-center justify-between gap-1 font-bold text-xs">
                                        <div className="p-1 bg-green-400 rounded-full w-2 h-2 md:w-4 md:h-4 text-xs"></div>
                                        <Link
                                            to={`/tennis/team/${match.competition}`}
                                        >
                                            {match.competition}
                                        </Link>
                                        <div className="text-sky-600 text-left">
                                            {match.status}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[2px]">
                                            <img
                                                className="w-[1.2rem] h-[1.2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/tv.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Match Scores */}
                            <div className="flex lg:items-end flex-col gap-2 w-full lg:w-auto">
                                {/* Scores Boxes */}
                                <div
                                    className="flex flex-1 lg:justify-end text-[14px] font-[600] gap-[2px] "
                                    style={{
                                        fontFamily:
                                            "Roboto Condensed, sans-serif",
                                    }}
                                >
                                    {match.scores.map((score, index) => (
                                        <div
                                            key={index}
                                            className={`${
                                                index % 2 === 0
                                                    ? "bg-[#72bbef]"
                                                    : "bg-[#faa9ba]"
                                            } rounded-md w-full lg:w-[5rem]   p-1 flex justify-center items-center text-[14px]`}
                                        >
                                            {score}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {index < matches.length - 1 && (
                            <hr className="border-t-2 border-gray-300" />
                        )}
                    </React.Fragment>
                ))}

                {/* View More Button */}
                <Link to="tennis">
                    {" "}
                    <div className="flex justify-end px-2 h-1rem] cursor-pointer">
                        <div className="text-sm font-bold">View More...</div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Tennis;

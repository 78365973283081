import React from "react";
import { FaUser } from "react-icons/fa";
import { TiThMenu } from "react-icons/ti";
import { useNavigate } from "react-router";

const BrandHeader = ({
    toggleDrawer,
    toggleLogin,
    isUserLoggedIn,
    isLoginOpen,
    isUserLogout,
}) => {
    const navigate = useNavigate();

    const handleLogoClick = (pathName) => {
        navigate(pathName);
    };
    return (
        <div
            className={` px-2  lg:pb-1 w-full ${
                isUserLoggedIn
                    ? "py-[0.1rem] md:py-[0rem]"
                    : "py-[1rem] lg:px-4 md:py-[0.5rem] lg:py-4 "
            }`}
            style={{ backgroundImage: "linear-gradient(#022c43, #18b0c8)" }}
        >
            {isUserLoggedIn && (
                <div className="  w-full">
                    <div className="flex justify-center pb-2 lg:pb-2 w-full">
                        <div className="bg-red-600  text-white text-[13px] w-[145px] text-center rounded-[6px] lg:py-[0.6px]">
                            Demo Account
                        </div>
                    </div>
                </div>
            )}
            <div className="flex justify-between items-center w-full">
                {/* brand Name */}
                <div className="flex items-center gap-[0px] md:gap-1 ">
                    <div
                        className="flex lg:hidden cursor-pointer"
                        onClick={toggleDrawer}
                    >
                        <TiThMenu className="w-[2rem] h-6 text-white" />
                    </div>

                    <img
                        src="https://images.staticcontent.io/rde/uimg/brand_logo.svg"
                        alt="pic"
                        className="w-[7rem] md:w-[10rem] lg:w-[12rem] cursor-pointer"
                        onClick={() => handleLogoClick("/")}
                    />
                </div>
                {/* login */}
                <div className="text-white">
                    {!isUserLoggedIn ? (
                        <button
                            onClick={() => toggleLogin()}
                            className="ring-2 flex items-center gap-1 text-white uppercase bg-custom-blue-light text-center px-[0.6rem] py-[0.6rem] md:px-[0.6rem] md:py-[0.4rem] text-[10px] md:text-[14px] font-bold rounded-lg border-[#72bbef]"
                            style={{
                                backgroundImage:
                                    "linear-gradient(#5ecbdd, #146578)",
                                borderColor: "#72bbef",
                            }}
                        >
                            <FaUser /> LOG IN
                        </button>
                    ) : (
                        <div
                            className="flex items-center flex-col gap-1 text-white"
                            onClick={() => isUserLogout()}
                        >
                            <div
                                className="flex items-center gap-[10px] bg-[#5ecbdd] border border-[#72bbef] px-2 rounded-[11px]"
                                style={{
                                    backgroundImage:
                                        " linear-gradient(180deg, #5ecbdd, #146578)",
                                }}
                            >
                                <img
                                    src="https://images.staticcontent.io/rde/uimg/coin.svg"
                                    alt="coin"
                                    className="w-3 h-3"
                                />
                                <span>2000.00</span>
                            </div>
                            <div
                                className="flex items-center gap-[5px] bg-[#5ecbdd] border border-[#72bbef] text-xs px-[1.3rem] py-1 rounded-[11px] "
                                style={{
                                    backgroundImage:
                                        "linear-gradient(180deg, #5ecbdd, #146578)",
                                }}
                            >
                                <h4 className="font-semibold">Exp :</h4>
                                <span>0.00</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BrandHeader;

import React from "react";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import GameGallery from "../../components/gameGallery/GameGallery";
import AllGames from "../../components/allGames/AllGames";
import Score from "../../components/allgamesScore/Score/Score";
import InPlay from "../inPlay/InPlay";

const Admin = ({ image, isUserLoggedIn }) => {
    return (
        <div className="mt-[0rem] md:mt-[0rem]">
            <ImageSlider image={image} />
            {/* <GameGallery />
            <AllGames /> */}
            {/* <InPlay /> */}
            <Score />
        </div>
    );
};

export default Admin;

import React, { useState } from "react";

const Aviator = () => {
    const [gameList] = useState([
        "https://images.staticcontent.io/casino/without-login/aviator/201206.sbe_aviator.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201206.sbe_aviator.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201204.sbe_roulette.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201205.sbe_hilo.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201207.sbe_dice.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201208.sbe_mines.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201209.sbe_plinko.jpg",
        "https://images.staticcontent.io/casino/without-login/aviator/201210.sbe_goal.jpg",
    ]);

    return (
        <div className="flex flex-col w-full mb-[2rem] md:mb-0">
            <div className="bg-[#18adc5] p-2">
                <div className="text-white text-sm font-bold">Casino</div>
            </div>
            <div className="grid gap-2 p-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 rounded-xl">
                {gameList.map((src, index) => (
                    <div
                        key={index}
                        className="relative flex justify-center border-4 border-[rgba(0,0,0,0.6)] rounded-[5px] group"
                    >
                        <img
                            src={src}
                            alt={`game ${index}`}
                            className="w-full h-auto"
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <span className="text-white font-bold bg-[#18adc5] rounded-full px-4 py-1 text-sm hover:bg-[#343a40]">
                                Play
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Aviator;

import React from "react";

const DownloadImage = () => {
    return (
        <div className="w-full md:px-2 py-2 ">
            <img
                className="rounded-lg h-16 w-full md:h-[6rem] lg:[10rem]"
                src="https://images.staticcontent.io/rde/login/pg-banner.webp"
                alt="pic"
            />
        </div>
    );
};

export default DownloadImage;

import React from "react";
import SoccerScore from "./soccerScore/SoccerScore";
import Tennis from "./tennis/Tennis";
import Election from "./election/Election";
import HoreseRacingScore from "./horeseRacingScore/HoreseRacingScore";
import GreyhounRacing from "./greyhounRacing/GreyhounRacing";
import CricketScore from "./cricketScore/CricketScore";

const Score = () => {
    return (
        <div className="flex flex-col gap-1 w-full bg-white">
            {/* cricket */}
            <div>
                {/* <CricketScore /> */}
                <CricketScore />
            </div>

            {/* soccer */}
            <div>
                <SoccerScore />
            </div>

            {/* tennis */}
            <div>
                <Tennis />
            </div>

            {/* election */}
            {/* <div>
                <Election />
            </div> */}

            {/* horseRacing */}
            <div>
                <HoreseRacingScore />
            </div>

            {/* Greyhound Racing*/}
            <div>
                <GreyhounRacing />
            </div>
        </div>
    );
};

export default Score;

import React, { useState } from "react";

const BetControl = ({ showBetControl, handleCloseBtn, oddValue }) => {
    const [betRange, setBetRange] = useState(null);

    const betPrice = (bet) => {
        setBetRange(bet);
    };

    const handleNumberClick = (number) => {
        setBetRange((prevBetRange) => {
            // Concatenate the clicked number to the existing betRange string
            const newBetRange = prevBetRange
                ? prevBetRange.toString() + number.toString()
                : number.toString();
            return newBetRange;
        });
    };

    const handleDeleteLastDigit = () => {
        setBetRange((prevBetRange) => {
            // Remove the last character from the current betRange state
            if (prevBetRange) {
                const newBetRange = prevBetRange.slice(0, -1); // Remove last character
                return newBetRange;
            }
            return prevBetRange; // If no betRange, return as is
        });
    };

    return (
        <>
            {showBetControl && (
                <div className="bg-gray-200 mb-4">
                    <div className="w-full px-1 py-1">
                        <div className="mt-[-0.3rem] rounded-md text-center flex flex-col items-start justify-normal w-full">
                            <div className="w-full grid grid-cols-2 gap-1 md:flex md:items-center md:gap-1 bg-[#beddf4] py-1 md:justify-end">
                                <div className="text-xs font-bold mx-4 hidden md:flex"></div>
                                <div className="flex">
                                    <span className="hidden md:block px-2 font-bold text-[14px]">
                                        Bangladesh- BOOKMAKER
                                    </span>
                                    <button
                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                        style={{
                                            backgroundColor:
                                                "rgb(164, 164, 164)",
                                        }}
                                    >
                                        -
                                    </button>
                                    {/* first input */}
                                    <input
                                        type="number"
                                        className="text-center bg-[#e9ecef] w-[7rem] md:w-[5.9rem] font-bold text-xs"
                                        value={oddValue}
                                        readOnly
                                    />
                                    <button
                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                        style={{
                                            backgroundColor:
                                                "rgb(164, 164, 164)",
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                                <div className="flex">
                                    <button
                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                        style={{
                                            backgroundColor:
                                                "rgb(164, 164, 164)",
                                        }}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        className="text-center bg-white w-[7rem] md:w-[5.8rem] font-bold text-xs"
                                        value={betRange}
                                    />
                                    <button
                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                        style={{
                                            backgroundColor:
                                                "rgb(164, 164, 164)",
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className=" w-full md:flex md:items-center md:justify-end md:py-1 bg-[#d4e8f8]">
                                <div className="grid grid-cols-6 w-full  md:flex md:justify-end">
                                    {[100, 500, 1000, 10000, 5000, 10000].map(
                                        (bet, id) => (
                                            <button
                                                key={id}
                                                className="bg-[#29a3c2] border-r border-black md:mx-1 px-4 py-1 font-bold text-[10px] md:rounded-[3px] hover:text-white"
                                                style={{
                                                    backgroundImage:
                                                        "linear-gradient(#61cce6, #29a3c2)",
                                                }}
                                                onClick={() => betPrice(bet)}
                                            >
                                                {bet}
                                            </button>
                                        )
                                    )}
                                </div>
                                <div className="grid grid-cols-7 w-full bg-white md:hidden">
                                    {[1, 2, 3, 4, 5, 6].map((number) => (
                                        <button
                                            key={number}
                                            className="p-2 font-bold border"
                                            onClick={() =>
                                                handleNumberClick(number)
                                            }
                                        >
                                            {number}
                                        </button>
                                    ))}
                                    <img
                                        src="https://images.staticcontent.io/rde/uimg/bt-icon.jpg"
                                        alt="icon"
                                        className="row-span-2 w-[1.5rem] m-auto cursor-pointer"
                                        onClick={handleDeleteLastDigit} // Updated to clear entire betRange state
                                    />
                                    {[7, 8, 9, 0, "00", "."].map(
                                        (number, index) => (
                                            <button
                                                key={index}
                                                className="p-2 font-bold border"
                                                onClick={() =>
                                                    handleNumberClick(number)
                                                }
                                            >
                                                {number}
                                            </button>
                                        )
                                    )}
                                </div>
                                <div className="grid grid-cols-2 gap-[3px] ">
                                    <button
                                        className="bg-[#c92233] text-white  rounded font-bold py-2 md:px-4 md:py-1 text-xs"
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(#ff5071, #c30529)",
                                        }}
                                        onClick={handleCloseBtn}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-[#026d02] text-white text-xs rounded font-bold whitespace-nowrap py-2 md:px-4 md:py-1"
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(#07af07, #026d02)",
                                        }}
                                    >
                                        Place Bet
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BetControl;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideDrawer from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import Login from "./pages/login/Login";
import LogoutSidebar from "./components/logoutSidebar/LogoutSidebar";

const Main = ({
    isLoginOpen,
    toggleLogin,
    demoIdClick,
    handleDemoIdClick,
    toggleLoggedIn,
    isUserLoggedIn,
    isOpenlogoutSidebar,
    isUserLogout,
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [shouldShowSidebar, setShouldShowSidebar] = useState(false);
    const location = useLocation();

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const showSidebar = () => {
        if (window.innerWidth <= 780) {
            return true;
        } else if (window.innerWidth > 780 && location.pathname !== "/") {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const handleResize = () => {
            setShouldShowSidebar(showSidebar());
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [location.pathname]);

    const navigate = useNavigate();

    return (
        <div className="relative flex flex-col w-full">
            {/* Login popup */}
            {isLoginOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <Login
                        toggleLogin={toggleLogin}
                        handleDemoIdClick={handleDemoIdClick}
                        toggleLoggedIn={toggleLoggedIn}
                    />
                </div>
            )}
            <LogoutSidebar
                isOpenlogoutSidebar={isOpenlogoutSidebar}
                isUserLogout={isUserLogout}
                toggleLoggedIn={toggleLoggedIn}
            />
            {/* Main content */}
            <Navbar
                isLoginOpen={isLoginOpen}
                toggleDrawer={toggleDrawer}
                toggleLogin={toggleLogin}
                demoIdClick={demoIdClick}
                isUserLoggedIn={isUserLoggedIn}
                isUserLogout={isUserLogout}
            />

            <div className="flex flex-1 overflow-hidden">
                {shouldShowSidebar && (
                    <SideDrawer
                        isDrawerOpen={isDrawerOpen}
                        toggleDrawer={toggleDrawer}
                        isUserLoggedIn={isUserLoggedIn}
                    />
                )}
                <div
                    className={`flex-1 md:overflow-y-auto w-full mb-2 ${
                        shouldShowSidebar
                            ? "lg:mt-[9.6rem] lg:h-[calc(100vh-10.9rem)] md:mt-[8.5rem]"
                            : "mt-[8.6] md:mt-[11.8rem] lg:mt-[9.2rem]"
                    }`}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Main;

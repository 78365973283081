import React, { useEffect, useState } from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
const Soccer = () => {
    const [currTime, setCurrTime] = useState(
        new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        })
    );
    const currDate = new Date();
    const options = { day: "2-digit", month: "long" };
    const formattedDate = currDate
        .toLocaleDateString("en-GB", options)
        .replace(/\//g, " ");

    const [matchData] = useState([
        {
            date: "16 Jun",
            time: "05:00",
            teams: "Durham V Lancashire",
            liveStatus: true,
            scores: [9, 11, "", "", 78, 85],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            date: "16 Jun",
            time: "05:00",
            teams: "Rajasthan Royals VR T10 v Delhi Capitals VR T10",
            liveStatus: true,
            scores: ["", "", "", "", 50, 57],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            date: "16 Jun",
            time: "05:00",
            teams: "Gujarat Titans VR T10 v Sunrisers Hyderabad VR T10",
            liveStatus: false,
            scores: [3.15, 3.2, "", 7.8, 4.2, ""],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            date: "16 Jun",
            time: "05:00",
            teams: "Mumbai Indians VR T10 v Lucknow Super Giants VR T10",
            liveStatus: false,
            scores: [1.15, 1.16, "", "", "", ""],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            date: "16 Jun",
            time: "05:00",
            teams: "Kolkata Knight Riders VR T10 v Royal Challengers Bangalore VR T10",
            liveStatus: false,
            scores: [2.98, 3, "", "", "", 1.52],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
    ]);

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + "...";
        }
        return str;
    };

    return (
        <div className="flex flex-col w-full mb-2">
            {/* In-play section */}
            <div className="flex-1 flex flex-col overflow-hidden ">
                <div className="bg-[#18adc5]">
                    <div className="flex flex-col items-start w-full">
                        <img
                            src="https://images.staticcontent.io/casino/banner/Soccer-banner.jpg"
                            alt="cricket"
                        />
                    </div>
                </div>
            </div>

            {/* ivl t10 score */}

            <div className="bg-">
                <div className="bg-[#18adc5] p-1 mt-1">
                    <div className="text-white text-sm font-bold">Soccer</div>
                </div>
                <div className="hidden lg:grid grid-cols-3 gap-[6rem] bg-white">
                    <div className="col-span-2">{""}</div>
                    <div className="flex justify-around items-end w-full font-bold font-roboto-condensed text-sm">
                        <div>1</div>
                        <div>X</div>
                        <div>2</div>
                    </div>
                </div>
                {/* Match Info */}
                {matchData.map((match, index) => (
                    <>
                        <div
                            key={index}
                            className="bg-white flex items-center gap-[2px] flex-col md:flex-row justify-between w-full   text-xs md:text-sm pb-[0.2rem] md:my-[0.1rem]"
                            style={{
                                fontFamilyL: "Roboto Condensed,sans-serif",
                            }}
                        >
                            <div className="flex items-center gap-1 flex-col md:flex-row w-full px-1">
                                <div className="flex items-center justify-between order-2 md:order-1  w-full md:w-auto lg:font-bold ">
                                    <div className="text-[#545454] md:text-[#022c44] md:font-bold  text-[13px] md:text-[14px] whitespace-nowrap flex items-center">
                                        <div>
                                            {match.date}
                                            {match.time} |
                                        </div>
                                        <div className="flex md:hidden">
                                            {" "}
                                            Womens One Day Inte...
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:hidden flex border-2 flex-shrink-0">
                                            <img
                                                className="w-4 h-4"
                                                src="https://images.staticcontent.io/rde/uimg/a-pin-mobile.svg"
                                                alt="select multiple"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between w-full order-1 lg:order-2 ">
                                    <div
                                        className="flex items-center gap-1  md:text-[14px]"
                                        style={{
                                            fontFamily:
                                                "Roboto Condensed, sans-serif",
                                        }}
                                    >
                                        <Link
                                            to={`/soccer/team/${match.teams}`}
                                            className="block md:hidden text-[13px] font-bold"
                                        >
                                            {truncateString(match.teams, 15)}
                                        </Link>
                                        <Link
                                            to={`/soccer/team/${match.teams}`}
                                            className="hidden md:block xl:hidden font-bold"
                                        >
                                            {truncateString(match.teams, 10)}
                                        </Link>
                                        <Link
                                            to={`/soccer/team/${match.teams}`}
                                            className="hidden lg:block font-bold"
                                        >
                                            {match.teams}
                                        </Link>
                                        <div className="text-[#18adc5] text-[10px] md:text-[13px] text-left font-bold">
                                            {match.liveStatus && "Live Now"}
                                        </div>
                                    </div>

                                    {/* icons images */}
                                    <div>
                                        {/* <div className="flex items-center gap-[2px]">
                                        <IoGameControllerOutline className="text-[1.5rem]" />
                                        {match.icons.map((icon, id) => (
                                            <img
                                                className="w-[1.2rem] h-[1.2rem]"
                                                src={icon}
                                                alt="icon"
                                            />
                                        ))}
                                    </div> */}
                                        <div className="flex items-center gap-[2px]">
                                            <img
                                                className="w-[1.2rem] h-[1.2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/tv.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Match Scores */}
                            <div className="flex lg:items-end flex-col gap-2 w-full md:w-auto px-[2px]">
                                {/* Scores Boxes */}
                                <div
                                    className="flex flex-1 md:justify-end text-[14px] font-[600]  gap-[2px] md:gap-[5px]"
                                    style={{
                                        fontFamily:
                                            "Roboto Condensed, sans-serif",
                                    }}
                                >
                                    {match.scores.map((score, index) => (
                                        <div
                                            key={index}
                                            className={`${
                                                index % 2 === 0
                                                    ? "bg-[#72bbef]"
                                                    : "bg-[#faa9ba]"
                                            } rounded-[3px] w-full md:w-[2.5rem] lg:w-[4.5rem] p-[2px] flex justify-center items-center text-[14px]`}
                                        >
                                            {score}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {index < matchData.length - 1 && (
                            <hr className="border-t-1 border-gray-200" />
                        )}
                    </>
                ))}
            </div>
        </div>
    );
};

export default Soccer;

import React from "react";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import GameGallery from "../../components/gameGallery/GameGallery";
import AllGames from "../../components/allGames/AllGames";
import Score from "../../components/allgamesScore/Score/Score";
import DownloadImage from "../../components/downloadimage/DownloadImage";
import Footer from "../../components/footer/Footer";
import { useOutletContext } from "react-router";

const Home = ({ image, demoIdCLick }) => {
    // const { x } = useOutletContext();
    // console.log(x);
    return (
        <div className="">
            <ImageSlider image={image} />
            <GameGallery />
            <AllGames />
            <Score />
            {!demoIdCLick && <DownloadImage />}
            {!demoIdCLick && <Footer />}
        </div>
    );
};

export default Home;

import React, { useState, useEffect } from "react";
import { IoMailSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import BetControl from "../betcontrol/BetControl";
import { TiMinus } from "react-icons/ti";
import { FaPlus } from "react-icons/fa";
const TeamDetails = () => {
    const [data, setData] = useState(null);
    const [toggleMatchOdd, setToggleMatchOdd] = useState(false);

    const [showBetControl, setShowBetControl] = useState({
        matchOdds: false,
        bookmarker: false,
        fancy: false,
    });
    const [selectedOddValue, setSelectedOddValue] = useState(null);
    const [currentSection, setCurrentSection] = useState(""); // Track the current section
    const { teamName } = useParams();
    const [selectedScore, setSelectedScore] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [toggleSection, setToggleSection] = useState({
        matchOdds: true,
        bookmarker: true,
        fancy: true,
    });
    const handleToggleSection = (section) => {
        setToggleSection((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
    const handleScoreClick = (score, section) => {
        setSelectedScore(score);
        setSelectedSection(section); // Set the selected section to show BetControl
    };

    const handleCloseBtn = () => {
        setSelectedSection(null); // Close the BetControl component
    };

    useEffect(() => {
        const fetchData = async () => {
            const mockData = {
                matchOdds: {
                    teams: [
                        { name: "India", scores: [9, "", 12, 15, 78, 85] },
                        { name: "Australia", scores: [9, "", 12, 15, "", 85] },
                    ],
                    min: 2.0,
                    max: 0.0,
                    team: teamName,
                    footerNote: "Tennis Bookmaker started in our Exchange...",
                },
                bookermarker: {
                    teams: [
                        { name: "India", scores: [9, "", 12, 15, 78, 85] },
                        { name: "Australia", scores: [9, "", 12, 15, "", 85] },
                        { name: "England", scores: [9, "", 12, 15, 78, 85] },
                    ],
                    min: 1.8,
                    max: 3.5,
                    footerNote: "Tennis Bookmaker started in our Exchange...",
                },
                fancy: {
                    teams: [
                        {
                            name: "16.3 Over WIW",
                            scores: [9, 84],
                            max: "100K",
                            mkt: "1000K",
                        },
                        {
                            name: "16 Over WIW",
                            scores: [78, 85],
                            max: "100K",
                            mkt: "1000K",
                        },
                        {
                            name: "20 Over WIW",
                            scores: [12, 15],
                            max: "100K",
                            mkt: "1000K",
                        },
                    ],
                    // min: 1.5,
                    // max: 2.5,
                },
                date: "21/06/2024 20:00",
                market: "Market",
                openBet: 0,
                // footerNote: "Tennis Bookmaker started in our Exchange...",
            };

            setData(mockData);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        };

        fetchData();
    }, [teamName]);

    const handleToggleBetControl = (section, oddValue) => {
        setSelectedOddValue(oddValue);
        setShowBetControl((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
        setCurrentSection(section);
    };

    if (!data) {
        return (
            <div className="m-auto h-full flex items-center justify-center">
                <div className="w-[5rem] h-[5rem] p-6 animate-spin border-t-4 rounded-full border-black"></div>
            </div>
        );
    }

    return (
        <div className="bg-[#e0e0e0] mb-4 flex flex-col lg:flex-row">
            <div className="flex flex-col mx-1 flex-1">
                <div className="text-white bg-[#18adc5] flex justify-between items-center p-2">
                    <div className="text-sm font-bold">
                        {teamName.slice(0, 20)}...
                    </div>
                    <div className="">
                        <div>
                            {" "}
                            <div className="text-sm font-bold">
                                {data.match}
                            </div>
                            <div className="text-sm font-bold">{data.date}</div>
                        </div>
                    </div>
                </div>
                <div className="md:hidden grid grid-cols-2 text-center rounded-md">
                    <div
                        className={`text-xs font-bold cursor-pointer py-2 ${
                            toggleMatchOdd
                                ? "bg-[#343a40] text-[#6c757d]"
                                : "text-white"
                        }`}
                        style={{
                            backgroundImage: !toggleMatchOdd
                                ? "linear-gradient(#5ecbdd, #146578)"
                                : "none",
                        }}
                        onClick={() => setToggleMatchOdd(!toggleMatchOdd)}
                    >
                        {data.market}
                    </div>
                    <div
                        className={`text-xs font-bold cursor-pointer py-2 ${
                            toggleMatchOdd
                                ? "text-white"
                                : "bg-[#343a40] text-[#6c757d]"
                        }`}
                        style={{
                            backgroundImage: toggleMatchOdd
                                ? "linear-gradient(#5ecbdd, #146578)"
                                : "none",
                        }}
                        onClick={() => setToggleMatchOdd(!toggleMatchOdd)}
                    >
                        Open Bets ({data.openBet})
                    </div>
                </div>
                {toggleMatchOdd && (
                    <div className="flex bg-white flex-col gap-[1px] font-[roboto-condensed] overflow-auto ">
                        <div className="grid grid-cols-4 gap-[1px] w-full text-white text-sm font-bold ">
                            <div className="bg-[#028dad] col-span-2 pl-2 py-1 my-auto">
                                Matched Bet
                            </div>
                            <div className="bg-[#028dad] pl-2 py-1 my-auto">
                                Odds
                            </div>
                            <div className="bg-[#028dad] pl-2 py-1 my-auto">
                                Stake
                            </div>
                        </div>
                        <div className="flex-1 overflow-auto h-[5rem]">
                            &nbsp;
                        </div>
                    </div>
                )}

                {/* Match Odds Section */}
                <div className="bg-white mb-2">
                    <div className="text-white text-sm flex justify-between items-center bg-[#18adc5] px-2 py-1">
                        <div className="font-bold text-white">Match Odds</div>
                        <div className="flex items-center gap-1">
                            <div className="font-bold bg-[#959595] text-xs px-2 py-[2px] rounded">
                                Cashout
                            </div>

                            <div className=" hidden md:flex items-center gap-1 text-sm font-bold text-white my-auto">
                                <div>
                                    {" "}
                                    Min: {data.matchOdds.min} | Max:{" "}
                                    {data.matchOdds.max}
                                </div>
                                <div
                                    className="w-[1rem] cursor-pointer"
                                    onClick={() =>
                                        handleToggleSection("matchOdds")
                                    }
                                >
                                    {toggleSection.matchOdds ? (
                                        <TiMinus />
                                    ) : (
                                        <FaPlus />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 px-1 w-full">
                        <div className=" md:hidden ">
                            <div className="text-xs font-bold text-[#6a6a6a] my-auto  md:text-[white]  ">
                                Min: {data.matchOdds.min} | Max:{" "}
                                {data.matchOdds.max}
                            </div>
                        </div>
                        <div className="hidden md:block">&nbsp;</div>
                        <div className=" flex items-center justify-center">
                            {/* <div className="text-xs  w-1/4 bg-blue-600"></div> */}
                            <div className="flex items-center justify-end md:justify-center w-full lg:pr-3">
                                <div>&nbsp;</div>
                                <div className="flex items-center justify-center gap-1 md:gap-[5px] my-1">
                                    <div className="bg-[#72bbef] text-center  w-[4rem]  md:w-[3.5rem] rounded-[5px] font-bold">
                                        Back
                                    </div>
                                    <div className="bg-[#faa9ba] text-center w-[4rem] md:w-[3.5rem] rounded-[5px] font-bold">
                                        Lay
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    {toggleSection.matchOdds && (
                        <div className="px-1">
                            {data &&
                                data.matchOdds.teams.map((ele, index) => (
                                    <React.Fragment key={index}>
                                        <div className="grid grid-cols-2 w-full">
                                            <div className="text-[14px] font-bold">
                                                {ele.name}
                                            </div>

                                            <div className="flex justify-end md:justify-end gap-1">
                                                {ele.scores.map(
                                                    (score, scoreIndex) => (
                                                        <div
                                                            key={scoreIndex}
                                                            className={`flex md:items-center text-black w-[4rem] md:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer ${
                                                                scoreIndex ===
                                                                    2 ||
                                                                scoreIndex === 3
                                                                    ? "block"
                                                                    : "hidden md:block"
                                                            }`}
                                                            style={{
                                                                backgroundColor:
                                                                    scoreIndex %
                                                                        2 ===
                                                                    0
                                                                        ? "#72bbef"
                                                                        : "#faa9ba",
                                                            }}
                                                            onClick={() =>
                                                                handleScoreClick(
                                                                    score,
                                                                    "matchOdds"
                                                                )
                                                            }
                                                        >
                                                            <div className="flex items-center flex-col text-[10px] font-bold m-auto">
                                                                {score}
                                                                {score ? (
                                                                    <div>
                                                                        200
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                ))}
                            {selectedSection === "matchOdds" && (
                                <BetControl
                                    showBetControl={true}
                                    handleCloseBtn={handleCloseBtn}
                                    oddValue={selectedScore}
                                />
                            )}
                        </div>
                    )}
                    {showBetControl.matchOdds &&
                        selectedOddValue !== null &&
                        currentSection === "matchOdds" && (
                            <BetControl
                                handleCloseBtn={() =>
                                    handleToggleBetControl("matchOdds", null)
                                }
                                showBetControl={showBetControl.matchOdds}
                                oddValue={selectedOddValue}
                            />
                        )}
                    {/* Footer Note */}
                    {toggleSection.matchOdds && (
                        <div className="text-[#b1031a] font-bold text-xs bg-white  text-right flex justify-end items-center gap-[2px]">
                            <IoMailSharp />
                            {data.matchOdds.footerNote}
                        </div>
                    )}
                </div>

                {/* Bookermarker Section */}
                <div className="bg-white mb-2">
                    <div className="text-white text-sm flex justify-between items-center bg-[#18adc5] px-2 py-1">
                        <div className="font-bold text-white">Bookmaker</div>
                        <div className="flex items-center gap-1">
                            <div className="font-bold bg-[#959595] text-xs px-2 py-[2px] rounded">
                                Cashout
                            </div>

                            <div className=" hidden md:flex  items-center gap-1 text-sm font-bold text-white my-auto">
                                <div>
                                    {" "}
                                    Min: {data.matchOdds.min} | Max:{" "}
                                    {data.matchOdds.max}
                                </div>
                                <div
                                    className="w-[1rem] cursor-pointer"
                                    onClick={() =>
                                        handleToggleSection("bookmarker")
                                    }
                                >
                                    {toggleSection.bookmarker ? (
                                        <TiMinus />
                                    ) : (
                                        <FaPlus />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 px-1 w-full">
                        <div className=" md:hidden ">
                            <div className="text-xs font-bold text-[#6a6a6a] my-auto  md:text-[white]  ">
                                Min: {data.matchOdds.min} | Max:{" "}
                                {data.matchOdds.max}
                            </div>
                        </div>
                        <div className="hidden md:block">&nbsp;</div>
                        <div className=" flex items-center justify-center">
                            {/* <div className="text-xs  w-1/4 bg-blue-600"></div> */}
                            <div className="flex items-center justify-end md:justify-center w-full lg:pr-3">
                                <div>&nbsp;</div>
                                <div className="flex items-center justify-center gap-1 md:gap-[5px] my-1">
                                    <div className="bg-[#72bbef] text-center  w-[4rem]  md:w-[3.5rem] rounded-[5px] font-bold">
                                        Back
                                    </div>
                                    <div className="bg-[#faa9ba] text-center w-[4rem] md:w-[3.5rem] rounded-[5px] font-bold">
                                        Lay
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {toggleSection.bookmarker && (
                        <div className="px-1">
                            {data &&
                                data.matchOdds.teams.map((ele, index) => (
                                    <React.Fragment>
                                        <div
                                            className="grid grid-cols-2 w-full"
                                            key={index}
                                        >
                                            <div className="text-[14px] font-bold">
                                                {ele.name}
                                            </div>

                                            <div className="flex justify-end md:justify-end gap-1">
                                                {ele.scores.map(
                                                    (score, scoreIndex) => (
                                                        <div
                                                            key={scoreIndex}
                                                            className={`flex md:items-center text-black w-[4rem] md:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer ${
                                                                scoreIndex ===
                                                                    2 ||
                                                                scoreIndex === 3
                                                                    ? "block"
                                                                    : "hidden md:block"
                                                            }`}
                                                            style={{
                                                                backgroundColor:
                                                                    scoreIndex %
                                                                        2 ===
                                                                    0
                                                                        ? "#72bbef"
                                                                        : "#faa9ba",
                                                            }}
                                                            onClick={() =>
                                                                handleScoreClick(
                                                                    score,
                                                                    "bookmarker"
                                                                )
                                                            }
                                                        >
                                                            <div className="flex items-center flex-col text-[10px] font-bold m-auto">
                                                                {score}
                                                                {score ? (
                                                                    <div>
                                                                        200
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                ))}
                            {selectedSection === "bookmarker" && (
                                <BetControl
                                    showBetControl={true}
                                    handleCloseBtn={handleCloseBtn}
                                    oddValue={selectedScore}
                                />
                            )}
                        </div>
                    )}

                    {/* Footer Note */}
                    {toggleSection.bookmarker && (
                        <div className="text-[#b1031a] font-bold text-xs bg-white  text-right flex justify-end items-center gap-[2px]">
                            <IoMailSharp />
                            {data.bookermarker.footerNote}
                        </div>
                    )}
                </div>

                {/* Fancy Section */}
                <div className="bg-white mb-2">
                    <div className="w-full bg-[#e0e0e0] mb-[1px]">
                        <div className="flex items-center justify-between bg-[#18adc5] px-2 font-bold w-[6rem] h-[2rem] rounded-t-lg">
                            <div className="text-sm font-bold text-white">
                                Fancy
                            </div>
                            <div
                                className="w-[1rem] cursor-pointer text-white  hidden md:flex "
                                onClick={() => handleToggleSection("fancy")}
                            >
                                {toggleSection.fancy ? <TiMinus /> : <FaPlus />}
                            </div>
                        </div>
                    </div>
                    {/*fancy header section  */}
                    <div className="flex items-center gap-4 text-xs font-bold bg-[#18adc5] text-white overflow-x-auto scrollbar-hide whitespace-nowrap px-1 py-[2px]">
                        <div className="bg-[#045662] p-2 rounded-md">All</div>
                        <div className="flex items-center gap-[3px] ">
                            <div>OVERS</div>
                            <div className="bg-red-500 px-[3px] rounded-sm">
                                1
                            </div>
                        </div>
                        <div className="flex items-center gap-[3px]">
                            <div>BASTMAN</div>
                            <div className="bg-red-500 px-[3px] rounded-sm">
                                1
                            </div>
                        </div>
                        <div className="flex items-center gap-[3px]">
                            <div>SINGLE OVER</div>
                            <div className="bg-red-500 px-[3px] rounded-sm">
                                1
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* Min and Max Odds */}

                    {toggleSection.fancy && (
                        <div className="grid grid-cols-2 gap-1 w-full text-xs md:text-sm">
                            <div className=" ">&nbsp;</div>
                            <div className="flex items-center justify-center md:justify-evenly lg:justify-start lg:gap-12 gap-16  md:pl-8 my-1   w-full ">
                                <div className=" text-right md:text-center  rounded-[5px] font-bold   ">
                                    NO
                                </div>
                                <div className=" text-right md:text-center  rounded-[5px] font-bold ">
                                    Yes
                                </div>
                            </div>
                        </div>
                    )}
                    <hr></hr>
                    {toggleSection.fancy && (
                        <div className="px-1 ">
                            {data &&
                                data.fancy.teams.map((ele, index) => (
                                    <React.Fragment>
                                        <div
                                            className="grid grid-cols-2 "
                                            key={index}
                                        >
                                            <div className=" text-[14px] font-bold  w-full">
                                                {ele.name}
                                            </div>
                                            <div className="flex items-start justify-between gap-[3px]  ">
                                                <div className="flex items-center justify-end lg:justify-start gap-[3px] w-full">
                                                    {" "}
                                                    {ele.scores.map(
                                                        (score, scoreIndex) => (
                                                            <div
                                                                key={scoreIndex}
                                                                className={`flex justify-center font-semibold text-black w-[6rem] md:w-[10rem] lg:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer `}
                                                                style={{
                                                                    backgroundColor:
                                                                        scoreIndex %
                                                                            2 ===
                                                                        0
                                                                            ? "#faa9ba"
                                                                            : "#72bbef",
                                                                }}
                                                                onClick={() =>
                                                                    handleScoreClick(
                                                                        score,
                                                                        "fancy"
                                                                    )
                                                                }
                                                            >
                                                                <div className="text-xs font-semibold text-center ">
                                                                    {score}
                                                                    {score ? (
                                                                        <div className="text-[10px]">
                                                                            200
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div className="w-1/3 hidden lg:block">
                                                    {ele.max && ele.mkt && (
                                                        <div className="flex items-end justify-end  flex-col text-xs font-bold font-roboto-condensed">
                                                            <div>
                                                                MAX:{ele.max}
                                                            </div>
                                                            <div>
                                                                MKT:{ele.mkt}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border  w-full"></hr>
                                    </React.Fragment>
                                ))}
                            {selectedSection === "fancy" && (
                                <BetControl
                                    showBetControl={true}
                                    handleCloseBtn={handleCloseBtn}
                                    oddValue={selectedScore}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {/* Match bet section */}
            <div className="hidden md:flex bg-white flex-col gap-[1px] font-[roboto-condensed] w-full lg:w-1/3 overflow-auto">
                <div
                    style={{
                        backgroundColor: "#5ecbdd",
                        backgroundImage: "linear-gradient(#5ecbdd, #146578)",
                        color: "#fff",
                        paddingLeft: "0.5rem",
                    }}
                >
                    Matched Bet
                </div>
                <div className="grid grid-cols-4 gap-[1px] w-full text-white text-sm font-bold">
                    <div className="bg-[#028dad] col-span-2 pl-2">
                        Matched Bet
                    </div>
                    <div className="bg-[#028dad] pl-2">Odds</div>
                    <div className="bg-[#028dad] pl-2">Stake</div>
                </div>
            </div>
        </div>
    );
};

export default TeamDetails;

import React, { useState } from "react";

const IndianPoker2 = () => {
    const [gameList] = useState([
        "https://images.staticcontent.io/casino/without-login/ip2/2-Card-Teenpatti.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/29-Card-Baccarat.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/29-Card-Baccarat.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/32-Cards-Casino.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/7-Up-&-Down-(Virtual).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/7-Up-and-7-Down.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Amar-Akbar-Anthony-(Virtual).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Amar-Akbar-Anthony.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Andar-Bahar%20(1).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Andar-Bahar-(Virtual).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Baccarat.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Bollywood-Casino.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Casino-Meter.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Casino-War.webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Dragon-Tiger%20(1).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Dragon-Tiger-(Virtual).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Hi-Low-(Virtual).webp",
        "https://images.staticcontent.io/casino/without-login/ip2/Hi-Low.webp",
    ]);

    return (
        <div className="flex flex-col w-full mb-[2rem] md:mb-0">
            <div className="bg-[#18adc5] p-2">
                <div className="text-white text-sm font-bold">Casino</div>
            </div>
            <div className="grid gap-2 p-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 rounded-xl">
                {gameList.map((src, index) => (
                    <div
                        key={index}
                        className="relative flex justify-center border-4 border-[rgba(0,0,0,0.6)] rounded-[5px] group"
                    >
                        <img
                            src={src}
                            alt={`game ${index}`}
                            className="w-full h-auto"
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <span className="text-white font-bold bg-[#18adc5] rounded-full px-4 py-1 text-sm hover:bg-[#343a40]">
                                Play
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IndianPoker2;

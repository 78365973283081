import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Home from "./pages/home/Home";
import InPlay from "./pages/inPlay/InPlay";
import Election from "./pages/election/Election";
import AeSexy from "./pages/aeSexy/AeSexy";
import Aviator from "./pages/aviator/Aviator";
import Betgames from "./pages/betgames/Betgames";
import Casino3 from "./pages/casino3/Casino3";
import ClubGames from "./pages/clubGames/ClubGames";
import Evolution from "./pages/evolution/Evolution";
import Greyhound from "./pages/greyhound/Greyhound";
import Horse from "./pages/horse/Horse";
import IndianPoker from "./pages/indianPoker/IndianPoker";
import IndianPoker2 from "./pages/Indianpoker2/IndianPoker2";
import LiveCasino from "./pages/liveCasino/LiveCasino";
import IVLT10 from "./pages/ivlt10/IVLT10";
import Soccer from "./pages/soccer/Soccer";
import Tennis from "./pages/tennis/Tennis";
import Vivo from "./pages/vivo/Vivo";
import WcWinner from "./pages/wcWinner/WcWinner";
import Cricket from "./pages/cricket/Cricket";
import FooterNav from "./components/footertab/FooterNav";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
import TeamDetails from "./components/teamDetails/TeamDetails";
import HorseNtts from "./components/horseNtt/HorseNtts";
import GreyhoundNtts from "./components/greyhoundNtt/GreyhoundNtts";
import SoccerNTTS from "./components/soccerNTTS/SoccerNTTS";
import TennisNTTS from "./components/tennisNTTS/TennisNTTS";
import img1 from "./assets/sliderImages/slider1.jpg";
import img2 from "./assets/sliderImages/slider2.png";
import img3 from "./assets/sliderImages/slider3.jpg";
import img4 from "./assets/sliderImages/slider4.jpg";
import img5 from "./assets/sliderImages/slider5.jpg";
import img6 from "./assets/sliderImages/slider6.jpg";
import img7 from "./assets/sliderImages/slider7.jpg";

function App() {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [demoIdClick, setDemoIdClick] = useState(false);
    const [isOpenlogoutSidebar, setIsOpenlogoutSidebar] = useState(false);

    const [image, setImage] = useState([
        "https://images.staticcontent.io/rde/login/main-banner0.webp",
        "https://images.staticcontent.io/rde/login/main-banner2.webp",
    ]);

    const toggleLogin = () => {
        setIsLoginOpen(!isLoginOpen);
    };

    const toggleLoggedIn = () => {
        setIsUserLoggedIn(!isUserLoggedIn);
    };

    const isUserLogout = () => setIsOpenlogoutSidebar(!isOpenlogoutSidebar);

    const handleDemoIdClick = () => {
        setImage(() => [img1, img2, img3, img4, img5, img6, img7]);
        setDemoIdClick(false);
        setIsLoginOpen(false);
    };

    return (
        <div className="w-full h-screen bg-[#ececed] relative overflow-auto">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Main
                                isLoginOpen={isLoginOpen}
                                toggleLogin={toggleLogin}
                                demoIdClick={demoIdClick}
                                handleDemoIdClick={handleDemoIdClick}
                                toggleLoggedIn={toggleLoggedIn}
                                isUserLoggedIn={isUserLoggedIn}
                                isUserLogout={isUserLogout}
                                isOpenlogoutSidebar={isOpenlogoutSidebar}
                            />
                        }
                    >
                        <Route path="/" element={<Home />} />
                        <Route
                            path="home"
                            element={
                                <Admin
                                    image={image}
                                    demoIdClick={demoIdClick}
                                    isUserLoggedIn={isUserLoggedIn}
                                />
                            }
                        />
                        <Route
                            path="inplay"
                            element={<InPlay isUserLoggedIn={isUserLoggedIn} />}
                        />
                        <Route path="cricket" element={<Cricket />} />
                        <Route
                            path="cricket/team/:teamName"
                            element={<TeamDetails />}
                        />
                        <Route path="election" element={<Election />} />
                        <Route
                            path="election/team/:teamName"
                            element={<TennisNTTS />}
                        />
                        <Route path="aesexy" element={<AeSexy />} />
                        <Route path="aviator" element={<Aviator />} />
                        <Route path="betgames" element={<Betgames />} />
                        <Route path="casino" element={<Casino3 />} />
                        <Route path="clubgames" element={<ClubGames />} />
                        <Route path="evolution" element={<Evolution />} />
                        <Route path="greyhound" element={<Greyhound />} />
                        <Route
                            path="greyhound/greyhound-nnts/:title/:teams"
                            element={<GreyhoundNtts />}
                        />
                        <Route path="horse" element={<Horse />} />
                        <Route
                            path="horse/horse-ntts/:title/:teams"
                            element={<HorseNtts />}
                        />
                        <Route path="indianpoker" element={<IndianPoker />} />
                        <Route path="indianpoker2" element={<IndianPoker2 />} />
                        <Route path="ivl" element={<IVLT10 />} />
                        <Route path="livecasino" element={<LiveCasino />} />
                        <Route path="soccer" element={<Soccer />} />
                        <Route
                            path="soccer/team/:teamName"
                            element={<SoccerNTTS />}
                        />
                        <Route path="tennis" element={<Tennis />} />
                        <Route
                            path="tennis/team/:teamName"
                            element={<TennisNTTS />}
                        />
                        <Route path="vivo" element={<Vivo />} />
                        <Route path="wcwinner" element={<WcWinner />} />
                        <Route
                            path="auth"
                            element={
                                <Login
                                    toggleLogin={toggleLogin}
                                    handleDemoIdClick={handleDemoIdClick}
                                    toggleLoggedIn={toggleLoggedIn}
                                />
                            }
                        />
                    </Route>
                </Routes>
                <div className="pt-[2rem] md:hidden">
                    <FooterNav
                        toggleLogin={toggleLogin}
                        isUserLogout={isUserLogout}
                        isUserLoggedIn={isUserLoggedIn}
                    />
                </div>

                {/* Login popup */}
            </BrowserRouter>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from "react";
import BetControl from "../../components/betcontrol/BetControl";
import { useParams } from "react-router";
import { TiMinus } from "react-icons/ti";
import { FaPlus } from "react-icons/fa";

const GreyhoundNtts = () => {
    const [data, setData] = useState(null);
    const [selectedScore, setSelectedScore] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const { title, teams } = useParams();
    const [toggleSection, setToggleSection] = useState(false);

    const colors = [
        "#d7e8f4",
        "#b7d5eb",
        "#72bbef",
        "#faa9ba",
        "#efd3d9",
        "#f6e6ea",
    ];

    useEffect(() => {
        const fetchData = async () => {
            const mockData = {
                winner: {
                    teams: [
                        { name: "India", scores: [9, "", 12, 15, 78, 85] },
                        { name: "Australia", scores: [9, "", 12, 15, "", 85] },
                        { name: "England", scores: [9, "", 12, 15, 78, 85] },
                    ],
                    min: 2.0,
                    max: 0.0,
                    team: "",
                },
                bookmarker: {
                    teams: [
                        { name: "India", scores: [9, 11, 15, 21, "", 85] },
                        { name: "Australia", scores: [9, 11, 15, 21, "", 85] },
                        { name: "England", scores: ["", 11, 15, 21, "", 85] },
                    ],
                    min: 1.8,
                    max: 3.5,
                },
                date: "21/06/2024 20:00",
                market: "Market",
                openBet: 0,
            };

            // Simulating async fetch
            setData(mockData);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        };

        fetchData();
    }, []);

    const handleScoreClick = (score, section) => {
        setSelectedScore(score);
        setSelectedSection(section); // Set the selected section to show BetControl
    };

    const handleCloseBtn = () => {
        setSelectedSection(null); // Close the BetControl component
    };

    return (
        <div className="flex overflow-hidden w-full">
            {/* In-play section */}
            <div className="flex-1 flex flex-col overflow-y-auto mx-1">
                <div className="bg-[#18adc5]">
                    <div className="flex flex-col items-start w-full">
                        <div className="flex items-center gap-1 w-full text-white text-sm font-bold py-1 px-2">
                            {/* <MdPushPin className="text-white border w-[1.2rem] h-[1.2rem] rounded-sm border-black" /> */}
                            <div>{title}</div>
                        </div>

                        <div className="flex items-center justify-between w-full bg-[#045662] text-xs font-semibold py-1 text-white px-2">
                            <div className="flex items-center gap-1">
                                <div className="text-sm font-bold">{teams}</div>
                            </div>
                            <div>
                                <div className="text-sm font-bold">
                                    {data ? data.date : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Winner */}
                    <div>
                        <div className="flex items-center justify-between w-full text-sm py-1 px-2 text-white">
                            <div className="font-bold text-white">7f Hcap</div>
                            <div
                                className="cursor-pointer"
                                onClick={() => setToggleSection(!toggleSection)}
                            >
                                {toggleSection ? <FaPlus /> : <TiMinus />}
                            </div>
                        </div>

                        {/* Table */}
                        <div className="bg-white text-black w-full">
                            {/* Header */}
                            <div className=" flex items-center justify-between">
                                <div className="text-xs  w-1/2"></div>
                                <div className="flex items-center justify-end md:justify-center  w-1/2 px-1">
                                    <div>&nbsp;</div>
                                    <div className="flex items-center justify-center gap-1 md:gap-[5px] my-1 md:pr-1 md:text-xs text-center">
                                        <div className="bg-[#72bbef] text-center w-[4rem]  md:w-[3rem] lg:w-[5rem] rounded-[5px] font-bold h-[30px] m-auto">
                                            Back
                                        </div>
                                        <div className="bg-[#faa9ba] text-center w-[4rem] md:w-[3rem] lg:w-[5rem] rounded-[5px] font-bold h-[30px]">
                                            Lay
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            {/* Winner board */}
                            <div className="px-1">
                                {data &&
                                    data.winner.teams.map((ele, index) => (
                                        <React.Fragment key={index}>
                                            <div className="grid grid-cols-2 w-full">
                                                <div className=" flex items-start gap-3">
                                                    <div className="flex flex-col items-center  text-sm font-bold">
                                                        <div>{index + 1}.</div>
                                                    </div>
                                                    <div className="flex flex-col items-start text-sm font-bold">
                                                        <div>Yama Rose</div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-end md:justify-normal gap-1">
                                                    {ele.scores.map(
                                                        (score, scoreIndex) => (
                                                            <div
                                                                key={scoreIndex}
                                                                className={`flex md:items-center  text-black w-[4rem] md:w-[3rem] lg:w-[5rem] min-h-[36px] my-1 rounded-[5px] cursor-pointer ${
                                                                    scoreIndex ===
                                                                        2 ||
                                                                    scoreIndex ===
                                                                        3
                                                                        ? "block"
                                                                        : "hidden md:block"
                                                                }`}
                                                                style={{
                                                                    backgroundColor:
                                                                        colors[
                                                                            scoreIndex
                                                                        ] ||
                                                                        "#f6e6ea",
                                                                }}
                                                                onClick={() =>
                                                                    handleScoreClick(
                                                                        score,
                                                                        "winner"
                                                                    )
                                                                }
                                                            >
                                                                <div className="flex items-center justify-end flex-col text-sm font-bold m-auto text-center">
                                                                    {score}
                                                                    {score ? (
                                                                        <div>
                                                                            200
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <hr />
                                        </React.Fragment>
                                    ))}
                                {selectedSection === "winner" && (
                                    <BetControl
                                        showBetControl={true}
                                        handleCloseBtn={handleCloseBtn}
                                        oddValue={selectedScore}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Match bet section */}
            <div className="hidden md:flex bg-white flex-col gap-[1px] font-[roboto-condensed] w-1/4 overflow-auto">
                <div
                    style={{
                        backgroundColor: "#5ecbdd",
                        backgroundImage: "linear-gradient(#5ecbdd, #146578)",
                        color: "#fff",
                        paddingLeft: "0.5rem",
                    }}
                >
                    Matched Bet
                </div>
                <div className="grid grid-cols-4 gap-[1px] w-full text-white text-sm font-bold">
                    <div className="bg-[#028dad] col-span-2 pl-2">
                        Matched Bet
                    </div>
                    <div className="bg-[#028dad] pl-2">Odds</div>
                    <div className="bg-[#028dad] pl-2">Stake</div>
                </div>
            </div>
        </div>
    );
};

export default GreyhoundNtts;

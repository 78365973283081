import React, { useState } from "react";
import Home from "../../assets/sidebar/home.png";
import inPlay from "../../assets/sidebar/inplay.png";
import WCwinner from "../../assets/sidebar/wcWinner.png";
import Cricket from "../../assets/sidebar/cricket.png";
import Soccer from "../../assets/sidebar/soccer.png";
import Tennis from "../../assets/sidebar/tennis.png";
import Horse from "../../assets/sidebar/horse.png";
import GreyHound from "../../assets/sidebar/grahound.png";
import Poker from "../../assets/sidebar/indianPoker.png";
import Poker2 from "../../assets/sidebar/indianPoker2.png";
import Aviator from "../../assets/sidebar/casino-icon.svg";
import Evolution from "../../assets/sidebar/evolution.png";
import Download from "../../assets/sidebar/download.png";
import { FaCaretDown } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaMobileScreenButton } from "react-icons/fa6";
import Accordion from "./../acordion/Acordion";
import { useNavigate } from "react-router";

const SideDrawer = ({ isDrawerOpen, toggleDrawer, isUserLoggedIn }) => {
    const [selectedTab, setSelectedTab] = useState(null);

    const sidebarTabs = [
        {
            icon: Home,
            tab: "Home",
            route: isUserLoggedIn ? "/home" : "/",
            hiddenOnLargeScreen: true,
        },
        { icon: inPlay, tab: "In-Play", route: "/inplay" },
        { icon: WCwinner, tab: "WC Winner 2024", route: "/wcwinner" },
        { icon: Cricket, tab: "Cricket", route: "/cricket" },
        { icon: Tennis, tab: "Tennis", route: "/tennis" },
        { icon: Soccer, tab: "Soccer", route: "/soccer" },
        { icon: GreyHound, tab: "Greyhound", route: "/greyhound" },
        { icon: Horse, tab: "Horse", route: "/horse" },
        { icon: Poker, tab: "Indian Poker", route: "/indianpoker" },
        { icon: Poker2, tab: "Indian Poker II", route: "/indianpoker2" },
        { icon: Aviator, tab: "Aviator", route: "/aviator" },
        { icon: Poker, tab: "AE SEXY", route: "/aesexy" },
        { icon: Evolution, tab: "Evolution", route: "/evolution" },
        { icon: Poker, tab: "Live Casino", route: "/livecasino" },
        { icon: Evolution, tab: "Vivo", route: "/vivo" },
        { icon: Evolution, tab: "Betgames", route: "/betgames" },
        { icon: Poker, tab: "Casino III", route: "/casino" },
        {
            icon: Download,
            tab: "Download APK",
            route: "/downloadapk",
        },
    ];
    const navigate = useNavigate();

    const accordionData = {
        Cricket: [
            {
                title: "Indian Premier League Virtual T0",
                children: [
                    {
                        title: "Lucknow Super Giants VR T10 v Gujarat Titans Vr T10",
                    },
                ],
            },
            {
                title: "Chalotte Edwards Cup",
                children: [
                    {
                        title: "North West Thunder v Southern Vipers",
                    },
                    {
                        title: "Central Sparks V Northern Diamonds",
                    },
                ],
            },
        ],
        Soccer: [
            {
                title: "Friendlies International",
                children: [
                    { title: "Scotland V Finland" },
                    { title: "Malta V Czech Republic" },
                ],
            },
        ],
        Tennis: [
            {
                title: "Men's Roland Garros 2023",
                children: [
                    { title: "Carlos Alcaraz V Sinner" },
                    { title: "Ruud V A Zverev" },
                ],
            },
        ],
        Horse: [
            {
                title: "GB-Goodwood",
                children: [{ title: "Goodwood 7th Jun" }],
            },
            {
                title: "FR-Compiegne",
                children: [{ title: "Compiegne (FRA) 7th Jun" }],
            },
        ],
        Greyhound: [
            {
                title: "GB-Towecester",
                children: [{ title: "Towcester 7th Jun" }],
            },
            {
                title: "GB-Newcastle",
                children: [{ title: "Newcastle 7th Jun" }],
            },
        ],
    };

    const handleTabClick = (tab, route) => {
        if (accordionData[tab]) {
            setSelectedTab((prevTab) => (prevTab === tab ? null : tab));
        } else {
            navigate(route);
            toggleDrawer();
        }
    };

    return (
        <div className="flex ">
            <div
                className={`fixed top-0 left-0 lg:h-[calc(100vh-10.2rem)]  h-full w-full bg-[#fff] flex flex-col transition-transform duration-500 ease-in-out transform z-50 lg:pb-16 lg:z-0 ${
                    isDrawerOpen ? "translate-x-0" : "-translate-x-full"
                } lg:flex lg:relative lg:translate-x-0 lg:w-[14rem] lg:mt-[9.6rem] overflow-y-auto`}
                style={{ maxHeight: "100%" }}
            >
                <div className="py-1 pl-2 bg-[#27a6c3] flex justify-between items-center">
                    <h1 className="text-sm font-semibold text-white">
                        Popular
                    </h1>
                    <button
                        className="text-white text-xl font-bold cursor-pointer lg:hidden"
                        onClick={toggleDrawer}
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="py-2">
                    {sidebarTabs.map((ele, id) => (
                        <div
                            className={`w-full ${
                                ele.hiddenOnLargeScreen ? "lg:hidden" : ""
                            }`}
                            key={id}
                        >
                            <div
                                className={`flex items-center py-1 px-2 gap-2 font-bold text-[#022c44] text-sm cursor-pointer hover:text-white hover:bg-[#27a6c3] hover:ml-1 duration-500`}
                                onClick={() =>
                                    handleTabClick(ele.tab, ele.route)
                                }
                            >
                                <img
                                    src={ele.icon}
                                    alt={ele.tab}
                                    className="rotate-90 w-[1.5rem]"
                                />
                                <span>{ele.tab}</span>
                                {[
                                    "Cricket",
                                    "Soccer",
                                    "Tennis",
                                    "Greyhound",
                                    "Horse",
                                ].includes(ele.tab) && (
                                    <span className="flex justify-end w-full">
                                        <FaCaretDown />
                                    </span>
                                )}
                            </div>
                            {ele.tab === selectedTab && (
                                <Accordion data={accordionData[ele.tab]} />
                            )}
                            {/* <hr className="text-black bg-black" /> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SideDrawer;

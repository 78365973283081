import React, { useState } from "react";
import { Link } from "react-router-dom";

const CricketScore = () => {
    // Array of match objects
    const matches = [
        {
            date: "25 May 09:30",
            competition: "ICC World Twen...",
            status: "Live Now",
            scores: [250, 200, 180, 220, 260, 240],
        },
        {
            date: "26 May 10:00",
            competition: "IPL 2023",
            status: "Upcoming",
            scores: [210, 190, 230, 200, 240, 220],
        },
        {
            date: "27 May 11:30",
            competition: "Big Bash League",
            status: "Live Now",
            scores: [200, 220, 210, 230, 250, 240],
        },
    ];

    // State to store the index of the clicked score and match
    const [selectedScore, setSelectedScore] = useState({
        matchIndex: null,
        scoreIndex: null,
        score: null,
    });

    // State for the counter inputs
    const [scoreValue, setScoreValue] = useState(0);
    const [betValue, setBetValue] = useState(0);

    const handleScoreClick = (matchIndex, scoreIndex, score) => {
        setSelectedScore({
            matchIndex,
            scoreIndex,
            score,
        });
        setScoreValue(score); // Initialize the input value with the clicked score
        setBetValue(0); // Initialize the bet value to 0
    };

    const handleValueChange = (type, value) => {
        if (type === "score") {
            setScoreValue((prev) => prev + value);
        } else if (type === "bet") {
            setBetValue((prev) => prev + value);
        }
    };

    const handleNumberClick = (number) => {
        if (number === "00") {
            setBetValue((prev) => parseInt(`${prev}00`, 10));
        } else {
            setBetValue((prev) => parseInt(`${prev}${number}`, 10));
        }
    };

    const handleSpecialNumberClick = (specialNumber) => {
        if (specialNumber === ".") {
            setBetValue((prev) => parseFloat(`${prev}.`));
        }
    };

    const handleDeleteClick = () => {
        setBetValue((prev) => {
            const newValue = prev.toString().slice(0, -1);
            return newValue ? parseInt(newValue, 10) : 0;
        });
    };

    const resetSelectedScore = () => {
        setSelectedScore({
            matchIndex: null,
            scoreIndex: null,
            score: null,
        });
    };

    return (
        <div className="w-full font-roboto-condensed flex flex-col">
            <div className="flex-grow">
                {/* Title */}
                <div className="bg-[#18adc5] p-1">
                    <div className="text-white text-sm font-bold">Cricket</div>
                </div>

                {/* Scores Header */}
                <div className="hidden lg:flex justify-end gap-[10rem] px-4 bg-gray-100">
                    <div className="text-lg font-semibold">1</div>
                    <div className="text-lg font-semibold">x</div>
                    <div className="text-lg font-semibold">2</div>
                </div>
                <hr />

                {/* Match Info */}
                {matches.map((match, matchIndex) => (
                    <React.Fragment key={matchIndex}>
                        <div className="flex items-center gap-2 flex-col lg:flex-row justify-between w-full px-1 pb-1 text-xs md:text-sm">
                            <div className="flex items-center gap-[0px] flex-col md:flex-row w-full ">
                                <div className="flex items-center justify-between order-2 md:order-1 w-full md:w-auto">
                                    <div className="text-[#545454] md:text-[#022c44] md:font-bold  text-[13px] md:text-[14px] whitespace-nowrap flex items-center">
                                        <div>
                                            {match.date}
                                            {match.time} |
                                        </div>
                                        <div className="flex md:hidden">
                                            {" "}
                                            Womens One Day Inte...
                                        </div>
                                    </div>
                                    <div>
                                        <div className="md:hidden flex border-2 flex-shrink-0">
                                            <img
                                                className="w-4 h-4"
                                                src="https://images.staticcontent.io/rde/uimg/a-pin-mobile.svg"
                                                alt="select multiple"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between w-full order-1 lg:order-2">
                                    <div className="flex items-center justify-between gap-1 font-bold text-xs">
                                        <div className="p-1 bg-green-400 rounded-full w-4 h-4 text-xs"></div>
                                        <Link
                                            to={`/cricket/team/${match.competition}`}
                                        >
                                            {match.competition}
                                        </Link>
                                        <div className="text-sky-600 text-left">
                                            {match.status}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[2px]">
                                            <img
                                                className="w-[1.2rem] h-[1.2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/tv.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                            <img
                                                className="w-[2rem] h-[2rem]"
                                                src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                alt="icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Match Scores */}
                            <div className="flex lg:items-end flex-col gap-2 w-full lg:w-auto">
                                {/* Scores Boxes */}
                                <div
                                    className="flex flex-1 lg:justify-end text-[14px] font-[600] gap-[2px] "
                                    style={{
                                        fontFamily:
                                            "Roboto Condensed, sans-serif",
                                    }}
                                >
                                    {match.scores.map((score, scoreIndex) => (
                                        <div
                                            key={scoreIndex}
                                            className={`${
                                                scoreIndex % 2 === 0
                                                    ? "bg-[#72bbef]"
                                                    : "bg-[#faa9ba]"
                                            } rounded-md w-full lg:w-[5rem] p-1 flex justify-center items-center text-[14px] cursor-pointer`}
                                            onClick={() =>
                                                handleScoreClick(
                                                    matchIndex,
                                                    scoreIndex,
                                                    score
                                                )
                                            }
                                        >
                                            {score}
                                        </div>
                                    ))}
                                </div>
                                {/* {selectedScore.matchIndex === matchIndex &&
                                    selectedScore.scoreIndex !== null && (
                                        <div className="mt-[-0.3rem] rounded-md text-center flex flex-col items-start justify-normal">
                                            <div className="w-full flex items-center gap-1 bg-[#beddf4] py-1">
                                            
                                                <div className="flex">
                                                    <button
                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                        onClick={() =>
                                                            handleValueChange(
                                                                "score",
                                                                -1
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(164, 164, 164)",
                                                        }}
                                                    >
                                                        -
                                                    </button>
                                                    <input
                                                        type="number"
                                                        className="text-center bg-[#e9ecef] w-[5.9rem] font-bold"
                                                        value={scoreValue}
                                                        readOnly
                                                    />
                                                    <button
                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                        onClick={() =>
                                                            handleValueChange(
                                                                "score",
                                                                1
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(164, 164, 164)",
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                
                                                <div className="flex">
                                                    <button
                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                        onClick={() =>
                                                            handleValueChange(
                                                                "bet",
                                                                -1
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(164, 164, 164)",
                                                        }}
                                                    >
                                                        -
                                                    </button>
                                                    <input
                                                        type="number"
                                                        className="text-center bg-[#e9ecef] w-[5.8rem] font-bold"
                                                        value={betValue}
                                                        readOnly
                                                    />
                                                    <button
                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                        onClick={() =>
                                                            handleValueChange(
                                                                "bet",
                                                                1
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "rgb(164, 164, 164)",
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className="grid grid-cols-5 w-full bg-[#29a3c2] "
                                                style={{
                                                    backgroundImage:
                                                        "linear-gradient(#61cce6, #29a3c2)",
                                                }}
                                            >
                                                {[
                                                    100, 500, 1000, 10000, 5000,
                                                ].map((bet, id) => (
                                                    <div
                                                        key={id}
                                                        className="border-r border-[#598eb3]  px-2 py-1 font-bold text-[10px]"
                                                    >
                                                        {bet}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="grid grid-cols-7  w-full bg-white">
                                                {[1, 2, 3, 4, 5, 6].map(
                                                    (number) => (
                                                        <button
                                                            key={number}
                                                            className=" p-2 font-bold border"
                                                            onClick={() =>
                                                                handleNumberClick(
                                                                    number
                                                                )
                                                            }
                                                        >
                                                            {number}
                                                        </button>
                                                    )
                                                )}
                                                <img
                                                    src="https://images.staticcontent.io/rde/uimg/bt-icon.jpg"
                                                    alt="icon"
                                                    className="row-span-2 w-[1rem]  m-auto"
                                                    onClick={handleDeleteClick}
                                                />
                                                {[7, 8, 9, 0, "00", "."].map(
                                                    (number, index) => (
                                                        <button
                                                            key={index}
                                                            className=" p-2 font-bold border"
                                                            onClick={() => {
                                                                if (
                                                                    number ===
                                                                    "."
                                                                )
                                                                    handleSpecialNumberClick(
                                                                        "."
                                                                    );
                                                                else if (
                                                                    number ===
                                                                    "00"
                                                                )
                                                                    handleNumberClick(
                                                                        "00"
                                                                    );
                                                                else
                                                                    handleNumberClick(
                                                                        number
                                                                    );
                                                            }}
                                                        >
                                                            {number}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                            <div className="grid grid-cols-2 gap-[3px]  w-full">
                                                <button
                                                    className="bg-[#c92233] text-white px-4 py-2 rounded font-bold"
                                                    onClick={resetSelectedScore}
                                                    style={{
                                                        backgroundImage:
                                                            "linear-gradient(#ff5071, #c30529)",
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="bg-[#026d02] text-white px-4 py-2 rounded font-bold"
                                                    style={{
                                                        backgroundImage:
                                                            "linear-gradient(#07af07, #026d02)",
                                                    }}
                                                >
                                                    Place Bet
                                                </button>
                                            </div>
                                        </div>
                                    )} */}
                            </div>
                        </div>
                        {matchIndex < matches.length - 1 && (
                            <hr className="border-t-2 border-gray-300" />
                        )}
                    </React.Fragment>
                ))}

                {/* View More Button */}
                <Link to="cricket">
                    {" "}
                    <div className="flex justify-end px-2 h-1rem] cursor-pointer">
                        <div className="text-sm font-bold">View More...</div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default CricketScore;

import React, { useState } from "react";

const Footer = () => {
    const [images] = useState([
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/evolution.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/ezugi.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/pragmatic.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/betgames.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/superspade.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/betsoft.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/spribe.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/logo-18plus.svg",
        "https://images.staticcontent.io/casino/banner/loginimages/mobile/evoplay.svg",
    ]);

    return (
        <div className="w-full  md:bg-[#045662] pb-4  lg:py-6">
            <div className="flex justify-center items-center w-full">
                <div className="flex flex-col items-center justify-center flex-wrap w-full px-4">
                    <div className="flex flex-wrap justify-center  items-center gap-1 md:gap-5 lg:gap-8 w-full">
                        {images.map((ele, id) => (
                            <img
                                key={id}
                                className="py-2 h-[2.5rem] md:h-[3rem]"
                                src={ele}
                                alt="Evolution"
                            />
                        ))}
                    </div>
                    <div className="text-center md:text-white text-[0.7rem] font-bold mt-2 px-4">
                        You must be over 18 years old, or the legal age at which
                        gambling or gaming activities are allowed under the law
                        or jurisdiction that applies to you. You must reside in
                        a country in which access to online gambling is allowed
                        for its residents.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

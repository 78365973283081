import React, { useState } from "react";

const Betgames = () => {
    const [gameList] = useState([
        "https://images.staticcontent.io/casino/without-login/betgames/Andar-Bahar.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Baccarat-9.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Bet-On-Poker.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Dice-Duel.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Lucky-5.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Lucky-6.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Lucky-7.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Poker-6.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Rock-On-Scissors.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Speedy-7.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/War-Of-Bets.webp",
        "https://images.staticcontent.io/casino/without-login/betgames/Wheel-Of-Fortune.webp",
    ]);

    return (
        <div className="flex flex-col w-full mb-[2rem] md:mb-0">
            <div className="bg-[#18adc5] p-2">
                <div className="text-white text-sm font-bold">Casino</div>
            </div>
            <div className="grid gap-2 p-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 rounded-xl">
                {gameList.map((src, index) => (
                    <div
                        key={index}
                        className="relative flex justify-center border-4 border-[rgba(0,0,0,0.6)] rounded-[5px] group"
                    >
                        <img
                            src={src}
                            alt={`game ${index}`}
                            className="w-full h-auto"
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <span className="text-white font-bold bg-[#18adc5] rounded-full px-4 py-1 text-sm hover:bg-[#343a40]">
                                Play
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Betgames;

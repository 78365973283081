import React, { useState } from "react";
import inplay from "../../assets/sidebar/inplay.png";
import remote from "../../assets/sidebar/remote.png";
import cricket from "../../assets/sidebar/cricket.png";
import tennis from "../../assets/sidebar/tennis.png";
import soccer from "../../assets/sidebar/soccer.png";
import Greyhound from "../../assets/sidebar/grahound.png";
import horse from "../../assets/sidebar/horse.png";
import { Link } from "react-router-dom";

const InPlay = () => {
    const [game] = useState([
        { icon: inplay, name: "Watch Live", liveCount: 0 },
        { icon: remote, name: "All Games", liveCount: 3 },
        { icon: cricket, name: "Cricket", liveCount: 0 },
        { icon: soccer, name: "Soccer", liveCount: 2 },
        { icon: tennis, name: "Tennis", liveCount: 0 },
        { icon: horse, name: "Horse Racing", liveCount: 0 },
        { icon: Greyhound, name: "Greyhound", liveCount: 0 },
    ]);

    const matches = [
        {
            date: "25 May 09:30",
            competition: "ICC World Twen...",
            status: "Live Now",
            sport: "Cricket",
            scores: [250, 200, 180, 220, 260, 240],
        },
        {
            date: "26 May 10:00",
            competition: "IPL 2023",
            status: "Upcoming",
            sport: "Cricket",
            scores: [210, 190, 230, 200, 240, 220],
        },
        {
            date: "27 May 11:30",
            competition: "Big Bash League",
            status: "Upcoming",
            sport: "Cricket",
            scores: [200, 220, 210, 230, 250, 240],
        },
        {
            date: "25 May 13:00",
            competition: "Premier League",
            status: "Live Now",
            sport: "Soccer",
            scores: [1, 0, "", "", 2, 1],
        },
        {
            date: "25 May 14:00",
            competition: "ATP Finals",
            status: "Upcoming",
            sport: "Tennis",
            scores: [1, 0, "", "", 2, 1],
        },
        {
            date: "25 May 15:00",
            competition: "Derby",
            status: "Live Now",
            sport: "Horse Racing",
            scores: [1, 0, "", "", 2, 1],
        },
        {
            date: "25 May 16:00",
            competition: "Greyhound Championship",
            status: "Live Now",
            sport: "Greyhound",
            scores: [1, 0, "", "", 2, 1],
        },
    ];

    const sports = ["Cricket", "Soccer", "Tennis", "Horse Racing", "Greyhound"];

    const [selectedScore, setSelectedScore] = useState({
        matchIndex: null,
        scoreIndex: null,
    });

    const [scoreValue, setScoreValue] = useState(0);
    const [betValue, setBetValue] = useState(0);

    const handleScoreClick = (matchIndex, scoreIndex, score) => {
        setSelectedScore((prevSelectedScore) => {
            if (
                prevSelectedScore.matchIndex === matchIndex &&
                prevSelectedScore.scoreIndex === scoreIndex
            ) {
                return {
                    matchIndex: null,
                    scoreIndex: null,
                };
            }
            return {
                matchIndex,
                scoreIndex,
            };
        });
        setScoreValue(score);
        setBetValue(0);
    };

    const handleValueChange = (type, value) => {
        if (type === "score") {
            setScoreValue((prev) => prev + value);
        } else if (type === "bet") {
            setBetValue((prev) => prev + value);
        }
    };

    const handleNumberClick = (number) => {
        setBetValue((prev) => parseInt(`${prev}${number}`, 10));
    };

    const handleSpecialNumberClick = (specialNumber) => {
        if (specialNumber === ".") {
            setBetValue((prev) => parseFloat(`${prev}.`));
        }
    };

    const handleDeleteClick = () => {
        setBetValue((prev) => {
            const newValue = prev.toString().slice(0, -1);
            return newValue ? parseInt(newValue, 10) : 0;
        });
    };

    const resetSelectedScore = () => {
        setSelectedScore({
            matchIndex: null,
            scoreIndex: null,
        });
    };

    return (
        <>
            <div className="flex  overflow-y-auto w-full pb-4 lg:pb-0 ">
                <div className="flex-1 flex flex-col overflow-hidden mx- w-full overflow-y-auto mx-1 bg-white">
                    <div className="bg-[#18adc5]">
                        <div className="text-white text-sm font-bold p-2">
                            In-play
                        </div>
                        <div className="bg-white flex gap-8 p-2 overflow-x-auto scrollbar-hide">
                            {game.map((ele, id) => (
                                <div
                                    key={id}
                                    className="flex items-center flex-col relative"
                                >
                                    <img
                                        src={ele.icon}
                                        alt={ele.name}
                                        className="w-[2rem] h-[2rem] md:w-[3rem] md:h-[3rem] transition-transform duration-300 transform hover:scale-110"
                                    />
                                    <div className="text-[10px] font-bold whitespace-nowrap">
                                        {ele.name}
                                    </div>
                                    {ele.liveCount > 0 && (
                                        <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-sm w-4 h-4 flex items-center justify-center text-xs">
                                            {ele.liveCount}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {sports.map((sport, sportIndex) => {
                        const sportMatches = matches.filter(
                            (match) =>
                                match.sport.toLowerCase() ===
                                sport.toLowerCase()
                        );

                        return (
                            <div key={sportIndex}>
                                <div className=" font-bold p-1 bg-[#18adc5] text-sm text-white">
                                    {sport}
                                </div>
                                {sportMatches.length > 0 ? (
                                    sportMatches.map((match, matchIndex) => (
                                        <React.Fragment key={matchIndex}>
                                            <div className="flex items-center gap-2 flex-col lg:flex-row justify-between w-full px- pb-1 text-xs md:text-sm">
                                                <div className="flex items-center gap-[0px] flex-col md:flex-row w-full">
                                                    <div className="flex items-center justify-between order-2 md:order-1 w-full md:w-auto">
                                                        <div className="text-[#545454] md:text-[#022c44] md:font-bold text-[13px] md:text-[14px] whitespace-nowrap flex items-center">
                                                            {/* <div>
                                                                {match.date}
                                                                {match.time} |
                                                            </div> */}
                                                            <div className="flex md:hidden">
                                                                Womens One Day
                                                                Inte...
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="md:hidden flex border-2 flex-shrink-0">
                                                                <img
                                                                    className="w-4 h-4"
                                                                    src="https://images.staticcontent.io/rde/uimg/a-pin-mobile.svg"
                                                                    alt="select multiple"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center justify-between w-full order-1 lg:order-2">
                                                        <div className="flex items-center justify-between gap-1 font-bold text-xs">
                                                            {match.status ===
                                                                "Live Now" && (
                                                                <div className="p-1 bg-[#6bbd11] rounded-full w-2 h-2 text-xs"></div>
                                                            )}

                                                            {/* <Link
                                                                to={`/cricket/team/${match.teams}`}
                                                            >
                                                                <div className="text-xs font-bold mx-4 hidden md:flex">
                                                                    {
                                                                        match.competition
                                                                    }
                                                                </div>
                                                            </Link> */}
                                                            <Link
                                                                to={`/cricket/team/${match.competition}`}
                                                            >
                                                                {
                                                                    match.competition
                                                                }
                                                            </Link>
                                                            <div className="text-sky-600 text-left">
                                                                {match.status}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="flex items-center gap-[2px]">
                                                                <img
                                                                    className="w-[1.2rem] h-[1.2rem]"
                                                                    src="https://images.staticcontent.io/rde/uimg/tv.svg"
                                                                    alt="icon"
                                                                />
                                                                <img
                                                                    className="w-[2rem] h-[2rem]"
                                                                    src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                                    alt="icon"
                                                                />
                                                                <img
                                                                    className="w-[2rem] h-[2rem]"
                                                                    src="https://images.staticcontent.io/rde/uimg/bm-icon1.svg"
                                                                    alt="icon"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex lg:items-end flex-col gap-2 w-full lg:w-auto">
                                                    <div
                                                        className="flex flex-1 lg:justify-end text-[14px] font-[600] gap-[2px]"
                                                        style={{
                                                            fontFamily:
                                                                "Roboto Condensed, sans-serif",
                                                        }}
                                                    >
                                                        {match.scores.map(
                                                            (
                                                                score,
                                                                scoreIndex
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        scoreIndex
                                                                    }
                                                                    className={`${
                                                                        scoreIndex %
                                                                            2 ===
                                                                        0
                                                                            ? "bg-[#72bbef]"
                                                                            : "bg-[#faa9ba]"
                                                                    } rounded-[4px] w-full lg:w-[5rem] p-1 flex justify-center items-center text-[14px] cursor-pointer`}
                                                                    onClick={() =>
                                                                        handleScoreClick(
                                                                            `${sportIndex}-${matchIndex}`,
                                                                            scoreIndex,
                                                                            score
                                                                        )
                                                                    }
                                                                >
                                                                    {score}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedScore.matchIndex ===
                                                `${sportIndex}-${matchIndex}` &&
                                                selectedScore.scoreIndex !==
                                                    null && (
                                                    <div className="w-full px-1 py-1">
                                                        <div className="mt-[-0.3rem] rounded-md text-center flex flex-col items-start justify-normal w-full">
                                                            <div className="w-full grid grid-cols-2 md:flex md:items-center md:gap-1 bg-[#beddf4] py-1 md:justify-end">
                                                                <div className="text-xs font-bold mx-4 hidden md:flex">
                                                                    {
                                                                        match.competition
                                                                    }
                                                                </div>

                                                                <div className="flex">
                                                                    <button
                                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                                        onClick={() =>
                                                                            handleValueChange(
                                                                                "score",
                                                                                -1
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "rgb(164, 164, 164)",
                                                                        }}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input
                                                                        type="number"
                                                                        className="text-center bg-[#e9ecef] w-[7rem] md:w-[5.9rem] font-bold text-xs"
                                                                        value={
                                                                            scoreValue
                                                                        }
                                                                        readOnly
                                                                    />
                                                                    <button
                                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                                        onClick={() =>
                                                                            handleValueChange(
                                                                                "score",
                                                                                1
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "rgb(164, 164, 164)",
                                                                        }}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                                <div className="flex">
                                                                    <button
                                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                                        onClick={() =>
                                                                            handleValueChange(
                                                                                "bet",
                                                                                -1
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "rgb(164, 164, 164)",
                                                                        }}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input
                                                                        type="number"
                                                                        className="text-center bg-[#e9ecef] w-[7rem] md:w-[5.8rem] font-bold text-xs"
                                                                        value={
                                                                            betValue
                                                                        }
                                                                    />
                                                                    <button
                                                                        className="text-white text-[1rem] font-bold w-[1.8rem] h-[1.5rem]"
                                                                        onClick={() =>
                                                                            handleValueChange(
                                                                                "bet",
                                                                                1
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor:
                                                                                "rgb(164, 164, 164)",
                                                                        }}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className=" w-full md:flex md:items-center md:justify-end md:py-1 bg-[#d4e8f8]">
                                                                <div className="grid grid-cols-6 w-full  md:flex md:justify-end">
                                                                    {[
                                                                        100,
                                                                        500,
                                                                        1000,
                                                                        10000,
                                                                        5000,
                                                                        10000,
                                                                    ].map(
                                                                        (
                                                                            bet,
                                                                            id
                                                                        ) => (
                                                                            <button
                                                                                key={
                                                                                    id
                                                                                }
                                                                                className="bg-[#29a3c2] border-r border-black md:mx-1 px-4 py-1 font-bold text-[10px] md:rounded-[3px] hover:text-white"
                                                                                onClick={() =>
                                                                                    setBetValue(
                                                                                        bet
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    backgroundImage:
                                                                                        "linear-gradient(#61cce6, #29a3c2)",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    bet
                                                                                }
                                                                            </button>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="grid grid-cols-7 w-full bg-white md:hidden">
                                                                    {[
                                                                        1, 2, 3,
                                                                        4, 5, 6,
                                                                    ].map(
                                                                        (
                                                                            number
                                                                        ) => (
                                                                            <button
                                                                                key={
                                                                                    number
                                                                                }
                                                                                className="p-2 font-bold border"
                                                                                onClick={() =>
                                                                                    setBetValue(
                                                                                        number
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    number
                                                                                }
                                                                            </button>
                                                                        )
                                                                    )}
                                                                    <img
                                                                        src="https://images.staticcontent.io/rde/uimg/bt-icon.jpg"
                                                                        alt="icon"
                                                                        className="row-span-2 w-[1.5rem] m-auto"
                                                                        onClick={
                                                                            handleDeleteClick
                                                                        }
                                                                    />
                                                                    {[
                                                                        7,
                                                                        8,
                                                                        9,
                                                                        0,
                                                                        "00",
                                                                        ".",
                                                                    ].map(
                                                                        (
                                                                            number,
                                                                            index
                                                                        ) => (
                                                                            <button
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="p-2 font-bold border"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        number ===
                                                                                        "."
                                                                                    )
                                                                                        handleSpecialNumberClick(
                                                                                            "."
                                                                                        );
                                                                                    else if (
                                                                                        number ===
                                                                                        "00"
                                                                                    )
                                                                                        handleNumberClick(
                                                                                            "00"
                                                                                        );
                                                                                    else
                                                                                        handleNumberClick(
                                                                                            number
                                                                                        );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    number
                                                                                }
                                                                            </button>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="grid grid-cols-2 gap-[3px] ">
                                                                    <button
                                                                        className="bg-[#c92233] text-white  rounded font-bold py-2 md:px-4 md:py-1 text-xs"
                                                                        onClick={
                                                                            resetSelectedScore
                                                                        }
                                                                        style={{
                                                                            backgroundImage:
                                                                                "linear-gradient(#ff5071, #c30529)",
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="bg-[#026d02] text-white text-xs rounded font-bold whitespace-nowrap py-2 md:px-4 md:py-1"
                                                                        style={{
                                                                            backgroundImage:
                                                                                "linear-gradient(#07af07, #026d02)",
                                                                        }}
                                                                    >
                                                                        Place
                                                                        Bet
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            {matchIndex <
                                                sportMatches.length - 1 && (
                                                <hr className="border-t-2 border-gray-300" />
                                            )}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <div className="p-2">
                                        {sport} has no live event.
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className="bg-white hidden md:flex flex-col gap-[1px] font-[roboto-condensed] w-1/4 overflow-auto">
                    <div
                        style={{
                            backgroundColor: "#5ecbdd",
                            backgroundImage:
                                "linear-gradient(#5ecbdd, #146578)",
                            color: "#fff",
                            paddingLeft: "0.5rem",
                        }}
                    >
                        Matched Bet
                    </div>
                    <div className="grid grid-cols-4 gap-[1px] w-full text-white text-sm font-bold">
                        <div className="bg-[#028dad] col-span-2 pl-2">
                            Matched Bet
                        </div>
                        <div className="bg-[#028dad] pl-2">Odds</div>
                        <div className="bg-[#028dad] pl-2">Stake</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InPlay;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Horse = () => {
    const [matchData] = useState([
        {
            title: "ZA",
            teams: "South Korea v China",
            liveStatus: true,
            scores: [2, 2.4, 3, 3.9, 78, 85],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            title: "GB",
            teams: "Australia v Palestine",
            liveStatus: false,
            scores: ["", "", "", "", 50, 57],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            title: "IE",
            teams: "Indonesia v Philippines",
            liveStatus: true,
            scores: [3.15, 3.2, "", 7.8, 4.2, ""],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            title: "FR",
            teams: "Indonesia v Philippines",
            liveStatus: true,
            scores: [3.15, 3.2, "", 7.8, 4.2, ""],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
        {
            title: "US",
            teams: "Indonesia v Philippines",
            liveStatus: true,
            scores: [3.15, 3.2, "", 7.8, 4.2, ""],
            bgColor1: "bg-blue-500",
            bgColor2: "bg-yellow-500",
            icons: [
                "https://images.staticcontent.io/rde/uimg/tv.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
                "https://images.staticcontent.io/rde/uimg/bm-icon1.svg",
            ],
        },
    ]);

    return (
        <div className="flex flex-col w-full bg-white">
            {/* In-play section */}
            <div className="flex-1 flex flex-col overflow-hidden">
                <div className="bg-[#18adc5]">
                    <div className="flex flex-col items-start w-full">
                        <img
                            src="https://images.staticcontent.io/casino/banner/horseracing-banner.jpg"
                            alt="horse"
                        />
                    </div>
                </div>
                {/* Horse updates */}
                {matchData.map((match, index) => (
                    <div key={index}>
                        <div className="bg-[#18adc5] p-2">
                            <div className="text-white text-sm font-bold">
                                {match.title}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 px-6 py-2">
                            <div
                                className="font-bold text-sm"
                                style={{
                                    fontFamily: "Roboto Condensed, sans-serif",
                                }}
                            >
                                {match.teams}
                            </div>
                            <Link
                                to={`horse-ntts/${match.title}/ ${match.teams}`}
                            >
                                <div className="flex items-center gap-6 font-[roboto-condensed] ">
                                    {match.scores.map((score, index) => (
                                        <div
                                            key={index}
                                            className="bg-[#e8e8e8] px-3 py-1 cursor-pointer"
                                        >
                                            {score !== "" ? score : "-"}
                                        </div>
                                    ))}
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Horse;
